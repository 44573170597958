import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { ThreeDots } from 'react-loader-spinner';
import PropTypes from 'prop-types';

export default function CheckoutForm({ intendData }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      'pi_3O7t3EDtp8ItkC9f1i04rMel'
    );
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/klarna-payment?id=${intendData.id}`,
      },
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
    supportedPaymentMethods: ['card', 'klarna'],
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onChange={(data) => setPaymentData(data)}
      />
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="error text-center">
          {message}
        </div>
      )}

      <div className="row">
        <div className="col-12 px-md-5 px-3">
          <button
            disabled={
              isLoading || !stripe || !elements || !paymentData?.complete
            }
            id="submit"
            className={
              isLoading || !stripe || !elements || !paymentData?.complete
                ? 'theme-button disable-button  w-100 mt-3'
                : 'theme-button  w-100 mt-3'
            }
          >
            <span id="button-text">
              {isLoading ? (
                <ThreeDots
                  height="20"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ display: 'contents' }}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                'Proceed Checkout'
              )}
            </span>
          </button>
        </div>
      </div>
    </form>
  );
}

CheckoutForm.propTypes = {
  intendData: PropTypes.object.isRequired,
};

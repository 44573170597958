import React, { useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link, useNavigate } from 'react-router-dom';
import LinkSinger from '../Components/LinkSinger';
import ReactPlayer from 'react-player/lazy';

const Index = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(true);
  const handleVIPTable = () => {
    navigate('/plan-visit', {
      state: 'vip_table',
    });
  };
  return (
    <>
      <MainLayout>
        <section className="hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-sm-last">
                <div className="hero-text">
                  <h2 className="main-heading">
                    A SERIES OF MUSICAL PERFORMANCES, AND CONSCIOUS MOMENTS IN A
                    MAGICAL PLACE
                  </h2>
                  {/* <p className="text-heading">
                    A series of Musical performances, and Conscious Moments in a Magical place.
                  </p> */}
                  <Link to="/event-passes" className="theme-button">
                    Event Pass
                  </Link>
                </div>
              </div>
              <div className="col-md-6 order-sm-first">
                <div
                  className="hero-images hero-video "
                  onClick={() => setIsPlaying(!isPlaying)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onTouchStart={() => {
                    setIsHovered(true);
                    setTimeout(() => {
                      setIsHovered(false);
                    }, 500);
                  }}
                >
                  <ReactPlayer
                    playing={isPlaying}
                    playsinline={true}
                    loop={false}
                    preload="false"
                    tabIndex={0}
                    muted={false}
                    className="react-player"
                    onEnded={() => setIsPlaying(!isPlaying)}
                    url={[
                      {
                        src: '../assets/video/home_page_video.mov',
                        type: 'video/Mp4',
                      },
                    ]}
                  />
                  {isHovered && (
                    <>
                      {isPlaying ? (
                        <i
                          className="fa fa-pause video-play animation"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-play video-play animation"
                          aria-hidden="true"
                        ></i>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="hero-text ps-0">
                  <h2 className="main-heading">Why Conscious Kiss</h2>
                  <p className="text-heading">
                    In a world of Fleeting moments, Conscious Kiss creates space
                    to pause and connect while we gather in community to share a
                    musical experience. The magic of Baja provides the perfect
                    backdrop for these unique but simple pleasures.
                  </p>
                  <div className="plan-vip">
                    <Link to="/plan-visit" className="theme-button">
                      Plan Your Visit
                    </Link>
                    <button onClick={handleVIPTable} className="theme-button">
                      Vip Table
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="hero-images">
                  <img
                    src="../assets/images/Baja-Sunset.png"
                    alt="hero"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cross-logo">
          <img
            src="../../assets/images/cross-ico-2.png"
            alt="cross_ico"
            className="img-fluid"
          />
          <img
            src="../assets/images/heart-ico-2.png"
            alt="heart_ico"
            className="img-fluid"
          />
        </section>

        <section className="expression">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className=" main-heading">Bold Expression</h3>
                <ul>
                  <li>
                    In the spirit of radical expression feel free to wear a
                    Mask, Costume, Dress up and be Bold. Of course, none of
                    these are mandatory, but it might make your experience a
                    little more exciting.
                  </li>
                  <li>
                    Other important themes for us are consent and being kind to
                    others and the land around you. Specifically by asking for
                    consent if you wish to engage physically, and by not
                    littering.
                  </li>
                  <li>Thank you !</li>
                </ul>
                {/* <Link to="/line-up" className="pass>
                  <p>Line Up</p>
                </Link>

                <Link to="/event-passes" className="pass">
                  <p>Event Pass</p>
                  <p>July 2023</p>
                </Link> */}
              </div>
            </div>
          </div>
        </section>
        <section className="weekend-day">
          <div className="container">
            <div className="row">
              <h2 className="opening main-heading">A Night of Memories</h2>
              <div className="col-md-12">
                <div className="weekend">
                  <Link to="/event-passes" className="event">
                    <p>Event Pass</p>
                    <p>Mar 1, 2024</p>
                  </Link>
                  <Link to="/line-up" className="event">
                    <p>Line Up</p>
                  </Link>
                </div>
              </div>
              <div className="music music-img">
                <img
                  src="../assets/images/section_03_left.jpg"
                  alt="gallery01"
                  className="img-fluid"
                />
                <img
                  src="../assets/images/image-7.png"
                  alt="image-7.png"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* <div className="join-now">
                <img
                  src="../assets/images/join_now_ico.png"
                  alt="join_now_ico"
                  className="img-fluid"
                />
                <h3 className="main-heading font-increase">Join Now!</h3>
                <p className="text-heading">
                  Don&apos;t wait any longer, sign up and get access to early
                  bird tickets, exclusive deals, and personalized event
                  recommendations. The party is waiting!
                </p>
                <Link to="#" className="theme-button pick-button me-2">
                  Sign Up
                </Link>
                <Link to="#" className="theme-button gray-button">
                  Explore Events
                </Link>
              </div> */}

        <LinkSinger />
      </MainLayout>
    </>
  );
};

export default Index;

import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
// import { Link } from 'react-router-dom';
import { API_GET_ACTIVE_LINEUP } from '../config/Endpoints';
// import Parser from 'html-react-parser';
// import moment from 'moment';
import UserId from '../Components/UserId';
// import VIPTableModal from './VIPTableModal';

import LinkSinger from '../Components/LinkSinger';

const LineUp = () => {
  const [lineUp, setLineUp] = useState({});
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchLineUp = async () => {
    setLoading(true);
    await fetch(API_GET_ACTIVE_LINEUP, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setEvent(result.data.eventDetails);
        setLineUp(result.data.eventLineup);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  useEffect(() => {
    fetchLineUp();
  }, []);

  return (
    <>
      <MainLayout>
        {loading && <div className="text-center fs-5 p-5">Loading....</div>}
        {!loading &&
          Object.keys(event).length === 0 &&
          Object.keys(lineUp).length === 0 && (
            <div className="coming-soon">
              <h4 className="closing">Coming Soon...</h4>
            </div>
          )}
        {!loading &&
          Object.keys(event).length > 0 &&
          Object.keys(event).length > 0 && (
            <>
              <section className="hero">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 ">
                      <h2 className="main-heading">LineUp</h2>
                    </div>
                    <div className="col-md-6">
                      <div className="music-cover">
                        {/* <h3>Sounds For The Weekend </h3> */}
                        <h3>Sounds For The Night</h3>
                      </div>
                      {/* <h4 className="closing ps-0 ps-md-5">
                        -Crania & Veleros Beach Club
                      </h4> */}
                      <h4 className="closing ps-0 ps-md-5">-Crania</h4>
                      <h4 className="closing ps-0 ps-md-5">-Mar 1,2024</h4>
                      {/* <h4 className="closing ps-0 ps-md-5">-Mar 1-3, 2024</h4> */}
                    </div>
                  </div>
                </div>
              </section>
              <section className="event-pass">
                {/* <div className="container-fluid">
                    <div className="row g-0">
                      <div className="col-md-12 mt-3 mt-md-0">
                        <div className="card-items">
                          <h3>OPENING WEEKEND</h3>
                          <div className="number-list number-custom">
                            <div className="list card-number">
                              <div className="month">
                                <h4>
                                  {moment(event?.opening_start_date).format(
                                    'ddd MMM DD '
                                  )}
                                  -
                                  {moment(event?.opening_end_date).format(
                                    ' ddd MMM DD '
                                  )}
                                </h4>
                                <h4>{Parser(`${event?.opening_location}`)}</h4>
                              </div>
                            </div>
                            <div className="card-list-sol small">
                              {Parser(`${lineUp?.opening_lineup}`)}
                            </div>
                          </div>
                        </div>
                      </div>

                      {event?.event_passes?.map((item, index) => (
                        <div className="col-md-6 mt-3 mt-md-0" key={index}>
                          <div
                            className={` ${
                              index === 1 ? 'card-items dark' : 'card-items'
                            }`}
                          >
                            <h3>{item?.name}</h3>
                            <div className="number-list number-custom">
                              <div className="list card-number">
                                <div className="month">
                                  <h4>
                                    {moment(item?.start_date).format(
                                      'ddd MMM DD '
                                    )}
                                    -
                                    {moment(item?.end_date).format(
                                      ' ddd MMM DD '
                                    )}
                                  </h4>
                                  <h4>{Parser(`${item?.location}`)}</h4>
                                </div>
                              </div>
                              <div className="card-list-sol small">
                                {Parser(`${lineUp?.opening_lineup}`)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                <div className="artists-images">
                  <div className="container">
                    <div className="row">
                      <div className="artists">
                        {/* <UserId
                          userImage="../assets/images/dj_eranhersh.jpg"
                          userName="Eran Hersh"
                          listImage="images-second"
                        />
                        <UserId
                          userImage="../assets/images/dj_eff.png"
                          userName="DJEFF"
                          listImage="images-second"
                        /> */}
                        <UserId
                          userImage="../assets/images/dj_joeski.jpg"
                          userName="JOESKI"
                          listImage="images-second"
                        />
                        <UserId
                          userImage="../assets/images/dj_yokoo.png"
                          userName="YokoO"
                          listImage="images-second"
                        />
                      </div>
                      <div className="artists space-add">
                        {/* <UserId
                          userImage="../assets/images/dj_diass.png"
                          userName="DIASS"
                          listImage="images-third"
                        /> */}
                        {/* <UserId
                          userImage="../assets/images/dj_adassiya.png"
                          userName="ADASSIYA"
                          listImage="images-third"
                        /> */}
                        <UserId
                          userImage="../assets/images/Bobi-Stevkovski.png"
                          userName="Bobi Stevkovski"
                          listImage="images-third"
                        />

                        <UserId
                          userImage="../assets/images/dj_Jessc.png"
                          userName="Jess C"
                          listImage="images-third"
                        />
                        <UserId
                          userImage="../assets/images/Patricio.jpg"
                          userName="Patricio Hegewish"
                          listImage="images-third patricio"
                        />
                        <UserId
                          userImage="../assets/images/Crowley.jpg"
                          userName="Mr.Crowley"
                          listImage="images-third"
                        />
                      </div>
                      <div className="artists space-add space-add-new d-none">
                        <UserId
                          userImage="../assets/images/dj_Jessc.png"
                          userName="Dj Jess C"
                          listImage="images-four"
                        />
                        <UserId
                          userImage="../assets/images/dj_ukiyo.png"
                          userName="Ukiyo"
                          listImage="images-four"
                        />
                        <UserId
                          userImage="../assets/images/dj_frausto.png"
                          userName="FRAUSTO"
                          listImage="images-four"
                        />
                        <UserId
                          userImage="../assets/images/dj_kat7.png"
                          userName="KAT7"
                          listImage="images-four"
                        />
                        <UserId
                          userImage="../assets/images/dj_bakean.png"
                          userName="BAKEAN"
                          listImage="images-four"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        <LinkSinger />
      </MainLayout>
    </>
  );
};

export default LineUp;

import React from 'react';
// import { Link } from 'react-router-dom';

const Thank = () => {
  return (
    <>
      <div className="">
        <header className="header-top">
          <div className="space-con">
            <h1>Thank You!</h1>
          </div>
        </header>
        <div className="tickets-vip thank-page">
          <div className="container">
            <div className="row">
              <img
                src="../assets/images/thank_you.svg"
                alt=""
                className="thank-image"
              />
              <h3 className="sub-heading-affiliate pt-5">
                Thank you for sharing an interest in our campaign. You'll get an
                email or text steps if We've kicked things off
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thank;

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import PropTypes from 'prop-types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function App({ clientSecret, intendData }) {
  const appearance = {
    theme: 'stripe',
    rules: {
      '.Label': {
        color: 'white',
        marginTop: '10px',
        marginBottom: '10px',
        textTransform: 'capitalize',
      },

      'p-HeightObserver': {
        height: '50px',
      },
      '.Input--invalid': {
        marginBottom: '0.75em',
      },
      '.Input': {
        backgroundColor: 'hsl(0, 0%, 8%)',
        border: '1px solid rgba(255, 255, 255, 0.5)',
        borderRadius: '0',
        color: 'rgb(212, 212, 212)',
        boxShadow: '0',
        OutLine: '0',
        marginBottom: '-0.75em',
      },

      '.Input:disabled': {
        backgroundColor: 'hsl(0, 0%, 8%)',
      },
      '.Input::placeholder': {
        color: 'transparent',
        fontWeight: '600',
      },

      '.Input:focus': {
        outLine: 0,
        borderColor: 'rgba(255, 255, 255, 0.5)',
        boxShadow: 0,
      },
      '.RedirectText': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm intendData={intendData} />
        </Elements>
      )}
    </div>
  );
}

App.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  intendData: PropTypes.object.isRequired,
};

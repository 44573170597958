import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import VIPTableForm from '../Pages/VIPTableForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { API_GET_ACTIVE_EVENT, API_VIP_ZONE } from '../config/Endpoints';
import { generateAvailableDatesVIP } from '../utils/helper';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ChooseTable = () => {
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [isZoneSelect, setIsZoneSelect] = useState(false);
  const [selectedZone, setSelectedZone] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [zoneTableList, setZoneTableList] = useState([]);
  const [selectedTable, setSelectedTable] = useState({});
  const [selectedTableId, setSelectedTableId] = useState('');
  const [zoneLsit, setZoneList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedDay, setSelectedDay] = useState('March 25 - Friday');
  const [selectedDate, setSelectedDate] = useState('Loading...');
  const [isVIP, setIsVIP] = useState(false);

  const fetchEvent = async () => {
    setLoading(true);
    await fetch(API_GET_ACTIVE_EVENT, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setEvent(result.data);
      })
      .catch((error) => console.log('error', error));
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  useEffect(() => {
    if (event?.event_passes) {
      const oneDayPasses = event.event_passes.filter(
        (item) => item.type === 'ONE_DAY'
      );
      const twoDayPasses = event.event_passes.filter(
        (item) => item.type === 'TWO_DAY'
      );
      if (oneDayPasses.length > 0) {
        const dates = generateAvailableDatesVIP(
          oneDayPasses[0].start_date,
          oneDayPasses[0].end_date
        );
        let newDatesArray = dates.map((item) => ({
          ...item,
          id: oneDayPasses[0].id,
          dayCount: 1,
        }));

        let obj = {
          actualDate:
            newDatesArray[0]?.actualDate + ' & ' + newDatesArray[1]?.actualDate,
          formatDate:
            newDatesArray[0]?.formatDate +
            ' & ' +
            newDatesArray[1]?.formatDate?.split(' ')?.[1],
          id: twoDayPasses[0].id,
          dayCount: 2,
        };

        newDatesArray.push(obj);
        setAvailableDates(newDatesArray);
        setSelectedDay(dates[0]?.actualDate);
        setSelectedDate(dates[0]?.formatDate);
        setSelectedEvent(newDatesArray[0]);
        handleNext(newDatesArray[0]);
      }
    }
  }, [event]);

  useEffect(() => {
    availableDates.map((item) => {
      if (item?.actualDate === selectedDay) {
        setSelectedDate(item.formatDate);
      }
    });
  }, [selectedDay]);

  const handleNext = async (event) => {
    await fetch(API_VIP_ZONE + `?event_pass_id=${event?.id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setIsZoneSelect(true);
        setZoneList(result.data);
      })
      .catch((error) => console.log('error', error));
  };

  const handleZoneSelection = async (zone) => {
    setSelectedTableId('');
    setSelectedZone(zone);
    let tableList = zoneLsit.filter((item) => item.id === zone);
    setZoneTableList(tableList[0].vip_zone_tables);
    // setSelectedTableId(tableList[0]?.vip_zone_tables[0]?.id);
    // setSelectedTable(tableList[0]?.vip_zone_tables[0]);
  };

  const handleChange = () => {
    setSelectedZone('');
    setIsZoneSelect(false);
    setZoneTableList([]);
    setSelectedTableId('');
    setSelectedTable({});
  };

  const handleSelectDate = (e) => {
    const { value } = e.target;
    setSelectedDay(value);
    let selectedData = availableDates.filter(
      (item) => item.actualDate === value
    );
    setSelectedEvent(selectedData[0]);
  };

  const handleZoneTable = (e) => {
    const { value } = e.target;
    setSelectedTableId(value);
    let selectedData = zoneTableList.filter(
      (item) => item.id === Number(value)
    );
    setSelectedTable(selectedData[0]);
  };

  return (
    <>
      <MainLayout>
        <div className="hero">
          <div className="container">
            <div className="row">
              <h2 className="main-heading">Book VIP Table</h2>
              <section className="event-pass event-new">
                <div className="d-none">
                  {!isVIP && isZoneSelect && (
                    <Link to="" className="change" onClick={handleChange}>
                      Change
                    </Link>
                  )}
                </div>

                <div className="container-fluid">
                  <div className="row g-0">
                    {!isVIP && (
                      <>
                        <div className="date-program">
                          <h3>{selectedDate}</h3>
                        </div>
                        <div className="card-items-list mb-5">
                          <div className="card-select mt-3 mb-5 false false d-none">
                            <div className=" card-items dark">
                              <h3>Select Date</h3>
                              <div className="number-list number-height ">
                                <div className="list">
                                  <div className="month">
                                    <h4>{selectedDate}</h4>
                                  </div>
                                </div>
                                {!isZoneSelect && (
                                  <>
                                    <div className="date-select-user-pro">
                                      <label htmlFor="text">Select Date</label>
                                      <select
                                        name="date"
                                        id="date"
                                        className="form-select form-control w-100"
                                        style={{ maxWidth: '280px' }}
                                        value={selectedDay}
                                        onChange={handleSelectDate}
                                        disabled={loading}
                                      >
                                        {availableDates.map((date, index) => (
                                          <option
                                            key={index}
                                            value={date.actualDate}
                                          >
                                            {date.formatDate}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div>
                                      <button
                                        className="theme-button"
                                        onClick={handleNext}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>

              {isZoneSelect && !isVIP && (
                <>
                  {zoneLsit?.map((item, index) => (
                    <div className="col-lg-6 mb-4" key={index}>
                      <div
                        className={`book-vip ${
                          selectedZone === item.id &&
                          !item.is_zone_booked &&
                          'border-add'
                        }   ${
                          selectedZone !== '' &&
                          selectedZone !== item.id &&
                          !item.is_zone_booked &&
                          'overlay-img'
                        } ${item.is_zone_booked && 'overlay-sold-out-img'}
                        
                        `}
                        onClick={() =>
                          item.is_zone_booked
                            ? false
                            : handleZoneSelection(item.id)
                        }
                      >
                        <img
                          src={item?.zone_image?.url}
                          alt={item.id}
                          className="img-fluid vip-table-border-list"
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
              <div className="img-select-table">
                <div className="row">
                  {zoneTableList?.length > 0 && !isVIP && isZoneSelect && (
                    <>
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="checkout-step new-add">
                          <div className="item">
                            <label htmlFor="">Select a Table</label>
                            <select
                              name="date"
                              id="date"
                              onChange={handleZoneTable}
                              value={selectedTableId}
                              required
                            >
                              <option
                                selected
                                disabled={selectedTableId ? true : false}
                              >
                                Select table
                              </option>
                              {zoneTableList?.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.id}
                                  disabled={item.booking_status}
                                >
                                  {item.table_number}
                                  {item.booking_status ? '  (Sold Out)' : ''}
                                </option>
                              ))}
                            </select>
                          </div>
                          {selectedTableId && (
                            <>
                              <div className="item ">
                                <label htmlFor="">Total</label>
                                <h5>USD ${selectedTable.price}</h5>
                              </div>
                              <div className="item b-b-r">
                                <label htmlFor="">Pay Now 50% </label>

                                <h5>USD ${selectedTable.price / 2}.00</h5>
                              </div>
                              <p className="note">
                                Pay a 50% deposit to reserve now, and the rest
                                will be collected at the venue.
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <Elements stripe={stripePromise}>
                    <VIPTableForm
                      selectedTableId={selectedTableId}
                      selectedEvent={selectedEvent}
                      isVIP={isVIP}
                      setIsVIP={setIsVIP}
                      selectedDate={selectedDate}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ChooseTable;

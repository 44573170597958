import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const WeekendGuide = () => {
  return (
    <>
      <MainLayout>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="main-heading">The Evening Guide</h1>
              </div>
              <div className="col-md-6">
                <div className="guide-text">
                  <p className="text-heading">
                    Conscious Kiss is a 1 Night Experience. This limited
                    capacity Festival will happen March 1, 2024 Crania Reclaim
                    in San Jose Del Cabo.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="images-list">
          <div className="container">
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="sea">
                  <img
                    src="../assets/images/first.jpg"
                    alt="first.jpg"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="sea">
                  <img
                    src="../assets/images/two-2.jpeg"
                    alt="two-2.jpeg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="days">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex  align-items-md-center justify-content-md-center">
                <h3 className="main-heading pb-md-0">Day 1</h3>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>Friday - March 1st, 2024</li>
                  <li>Crania Doors open at 8pm - The Music & The Moment</li>
                  <li> 1 Venue</li>
                  <li> 6 Acts</li>
                  <li> Endless Memories</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="images-list">
          <div className="container">
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="sea">
                  <img
                    src="../assets/images/six-6.jpg"
                    alt="six-6.jpg"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="sea">
                  <img
                    src="../assets/images/seven-7.jpg"
                    alt="seven-7.jpg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="event-pass px-0">
          <div className="container">
            <div className="row g-0">
              <div className="col-md-6 mt-3 mt-md-0">
                <div className="card-items ">
                  <h3>Bag Policy</h3>
                  <div className="number-list number-custom">
                    <div className="card-list-sol small">
                      <ul>
                        <li>
                          Small purses, backpacks and fanny packs are allowed.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6  mt-3 mt-md-0">
                <div className="card-items dark">
                  <h3>Arrival & Wristbands</h3>
                  <div className="number-list number-custom">
                    <div className="card-list-sol small">
                      <ul>
                        <li>
                          Please have your mobile confirmation or your email
                          confirmation available to be scanned upon arrival at
                          Crania Entrance.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="images-list">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="main-heading">Cashless</h3>
              </div>
              <div className="col-md-6">
                <div className="days cashless">
                  <ul>
                    <li className="main-heading">- More to come on this</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="border-line">
          <div className="container">
            <hr />
          </div>
        </section>
        <section className="images-list">
          <div className="container">
            <div className="row">
              <div className="items">
                <h3 className="main-heading">Allowed Items</h3>
                <ul>
                  <li>Empty camelback</li>
                  <li>Cellphones</li>
                  <li>Fanny packs, small purses and backpacks</li>
                  <li>Ear plugs</li>
                  <li>Power bank</li>
                  <li>Closed prescription drugs</li>
                  <li>Knee pads, crutches or medical equipment in use</li>
                  <li>Soft chemlights or non-projectile chemlight clothing</li>
                  <li>Closed gum packet</li>
                  <li>Closed deodorant stick</li>
                  <li>Towels or blankets to sit on the grass</li>
                  <li>Caps</li>
                  <li>Sealed eye drops</li>
                  <li>Hula hoops</li>
                  <li>
                    Disposable cameras & non-professional flash & recording
                    cameras less than 12 cm
                  </li>
                  <li>Cream or stick sunblock (no aerosols)</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="images-list">
          <div className="container">
            <div className="row">
              <div className="items">
                <h3 className="main-heading">Prohibited Items</h3>
                <ul>
                  <li>Illegal substances</li>
                  <li>Drones or professional cameras</li>
                  <li>Pets</li>
                  <li>Food or Drinks</li>
                  <li>Glass and or sharp objects</li>
                  <li>Longs chains considered dangerous</li>
                  <li>Lighters or matches</li>
                  <li>Liquid for vapes</li>
                  <li>Chairs</li>
                  <li>Musical instruments</li>
                  <li>Liquid makeup and open tampons/pads</li>
                  <li>Pens, markers, or paint in can</li>
                  <li>Balls and frisbees</li>
                  <li>Boot bags</li>
                  <li>Tents</li>
                  <li>Video cameras or audio recording equipment</li>
                  <li>Perfumes</li>
                  <li>Aerosol sprays</li>
                  <li>Alcoholic or energy drinks</li>
                  <li>Umbrellas</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="border-line">
          <div className="container">
            <hr />
          </div>
        </section>
        <section className="images-list">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="days text-center">
                  <h3 className="main-heading">VIP Tables - Now Available</h3>
                  <p className="text-heading">
                    To request a VIP table, please fill out the request form:
                    <br />
                    <Link
                      to="/choose-table"
                      className="theme-button click-here mt-4"
                    >
                      Reserve Table
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default WeekendGuide;

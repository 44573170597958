import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PhoneNumberField from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import SimpleReactValidator from 'simple-react-validator';
import { MultiSelect } from 'react-multi-select-component';
import {
  API_GET_ACTIVE_EVENT,
  API_SEND_REQUEST_FORM,
} from '../../config/Endpoints';
import countryList from 'react-select-country-list';

const Application = () => {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const navigate = useNavigate();

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: '',
    instagram: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    paypal_email: '',
    questions: '',
    sign: '',
    signdate: getCurrentDate(),
    event_pass_id: '',
    social_tiktok: '',
    social_tiktok_followers: '',
    social_youtube: '',
    social_youtube_subscribers: '',
    social_x: '',
    social_x_followers: '',
    social_podcast: '',
    social_podcast_followers: '',
    social_facebook: '',
    social_facebook_followers: '',
  });
  const [selected, setSelected] = useState([]);
  const [event, setEvent] = useState({});
  const [termAndCondiotion, setTermAndCondiotion] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const countryListOption = countryList().getData();
  const fetchEvent = async () => {
    await fetch(API_GET_ACTIVE_EVENT, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setEvent(result.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          event_pass_id: result?.data?.event_passes[0]?.id,
        }));
      })
      .catch((error) => console.log('error', error));
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  const options = [
    {
      label: 'TikTok',
      value: 'TikTok',
      field1: 'TikTok Profile',
      name: 'social_tiktok',
      value1: formData.social_tiktok,
      field2: 'Followers',
      name2: 'social_tiktok_followers',
      value2: formData.social_tiktok_followers,
    },
    {
      label: 'YouTube',
      value: 'YouTube',
      field1: 'YouTube Channel',
      name: 'social_youtube',
      value1: formData.social_youtube,
      field2: 'Subscribers',
      name2: 'social_youtube_subscribers',
      value2: formData.social_youtube_subscribers,
    },
    {
      label: 'X',
      value: 'X',
      field1: 'Twitter Profile',
      name: 'social_x',
      value1: formData.social_x,
      field2: 'Followers',
      name2: 'social_x_followers',
      value2: formData.social_x_followers,
    },
    {
      label: 'Podcast',
      value: 'Podcast',
      field1: 'Podcast Name',
      name: 'social_podcast',
      value1: formData.social_podcast,
      field2: 'Monthly Listeners',
      name2: 'social_podcast_followers',
      value2: formData.social_podcast_followers,
    },
    {
      label: 'Facebook',
      value: 'Facebook',
      field1: 'Facebook',
      name: 'social_facebook',
      value1: formData.social_facebook,
      field2: 'Followers',
      name2: 'social_facebook_followers',
      value2: formData.social_facebook_followers,
    },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: event,
    }));
    try {
      const phoneNumberObj = parsePhoneNumberFromString(event);
      setIsValidPhoneNumber(phoneNumberObj ? phoneNumberObj.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumber(false);
    }
  };

  const handleInputKeyPress = (event) => {
    const maxLength = 16; // Maximum length requirement
    const inputValue = event.target.value;

    if (
      inputValue.length >= maxLength &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      event.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formValid = validator.current.allValid();
    if (formValid && agree && termAndCondiotion) {
      setLoading(true);
      var formdata = new FormData();
      formdata.append('firstname', formData.firstName);
      formdata.append('lastname', formData.lastName);
      formdata.append('phone', formData.phone);
      formdata.append('email', formData.email);
      formdata.append('dob', formData.dob);
      formdata.append('instagram', formData.instagram);
      formdata.append('address1', formData.address1);
      formdata.append('address2', formData.address2);
      formdata.append('city', formData.city);
      formdata.append('state', formData.state);
      formdata.append('zip', formData.zip);
      formdata.append('country', formData.country);
      formdata.append('paypal_email', formData.paypal_email);
      formdata.append('questions', formData.questions);
      formdata.append('sign', formData.sign);
      formdata.append('signdate', formData.signdate);
      formdata.append('event_pass_id', formData.event_pass_id);
      formdata.append('social_tiktok', formData.social_tiktok);
      formdata.append(
        'social_tiktok_followers',
        formData.social_tiktok_followers
      );
      formdata.append('social_youtube', formData.social_youtube);
      formdata.append(
        'social_youtube_subscribers',
        formData.social_youtube_subscribers
      );
      formdata.append('social_x', formData.social_x);
      formdata.append('social_x_followers', formData.social_x_followers);
      formdata.append('social_podcast', formData.social_podcast);
      formdata.append(
        'social_podcast_followers',
        formData.social_podcast_followers
      );
      formdata.append('social_facebook', formData.social_facebook);
      formdata.append(
        'social_facebook_followers',
        formData.social_facebook_followers
      );
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };
      fetch(API_SEND_REQUEST_FORM, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setTimeout(() => {
              setLoading(false);
              navigate('/thank');
            }, 2000);
          } else {
            setLoading(false);
            setIsError(true);
            const fieldName = Object.keys(result?.message?.errors)[0];
            setError(result?.message?.errors?.[fieldName][0]);
            setTimeout(() => {
              setIsError(false);
              setError('');
            }, 3000);
          }
        })
        .catch((error) => {
          console.log('error', error);
          setIsError(true);
          setError('Something went wrong, please try again!');
          setTimeout(() => {
            setIsError(false);
            setError('');
          }, 3000);
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div className={loading ? 'overlay-affiliate' : ''}>
      <header className="header-top">
        <div className="space-con">
          <h1>Application</h1>
          <div className="choose-button">
            <Link to="/opportunity" className="text-sm">
              Back
            </Link>
          </div>
        </div>
      </header>
      <form action="" className="tickets-vip pd-set" onSubmit={handleSubmit}>
        <div className="container-fluid">
          {/* {loading && <div className="text-center fs-5 p-5">Loading....</div>} */}
          <div className="row">
            <h3 className="heading-all">Contact Information</h3>
            <div className="col-md-6">
              <label htmlFor="FirstName" className="input-heading ">
                First Name
              </label>
              <input
                type="text"
                className="form-control new-input"
                placeholder="Bell"
                value={formData.firstName}
                onChange={handleInputChange}
                onKeyPress={(e) => {
                  const keyCode = e.charCode || e.keyCode;
                  // Allow only numerics, letters, and spaces
                  if (
                    !(
                      (keyCode >= 48 && keyCode <= 57) || // Numerics
                      (keyCode >= 65 && keyCode <= 90) || // Uppercase letters
                      (keyCode >= 97 && keyCode <= 122) || // Lowercase letters
                      keyCode === 32
                    )
                  ) {
                    // Space
                    e.preventDefault();
                  }
                }}
                name="firstName"
              />
              {validator.current.message(
                'firstName',
                formData.firstName,
                'required'
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="LastName" className="input-heading ">
                Last Name
              </label>
              <input
                type="text"
                className="form-control new-input"
                placeholder="Silva"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {validator.current.message(
                'lastName',
                formData.lastName,
                'required'
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="Number" className="input-heading ">
                Phone Number
              </label>
              <div className="out-field">
                <PhoneNumberField
                  name="phone"
                  international
                  countryCallingCodeEditable={false}
                  placeholder="Phone Number*"
                  onChange={handlePhoneNumberChange}
                  defaultCountry="US"
                  value={formData.phone}
                  error={!isValidPhoneNumber}
                  onKeyPress={handleInputKeyPress}
                  className="phone-number"
                />
              </div>
              {validator.current.message('phone', formData.phone, 'required')}
              {/* <p className="text-sm f-c">
                Not you?
                <span
                  className="text-sm f-c-b"
                  data-bs-toggle="modal"
                  data-bs-target="#LogIn"
                >
                  Log in with a different phone number.
                </span>
              </p> */}
            </div>
            <div className="col-md-6">
              <label htmlFor="Email" className="input-heading ">
                Email
              </label>
              <input
                type="email"
                className="form-control new-input"
                placeholder="Silva@gmail.com"
                value={formData.email}
                name="email"
                onChange={handleInputChange}
              />
              {validator.current.message('email', formData.email, 'required')}

              {/* {validator.message('email', formData.email, 'required')} */}
              {/* <p className="text-sm f-c">
                Not you?
                <span
                  className="text-sm f-c-b"
                  data-bs-toggle="modal"
                  data-bs-target="#LogIn"
                >
                  Log in with a different phone number.
                </span>
              </p> */}
            </div>
            <div className="col-md-6">
              <label htmlFor="date" className="input-heading ">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control new-input"
                value={formData.dob}
                name="dob"
                onChange={handleInputChange}
              />
              {validator.current.message('dob', formData.dob, 'required')}
            </div>

            <div className="col-md-6">
              <label htmlFor="name" className="input-heading ">
                Instagram Handle
              </label>
              <input
                type="text"
                className="form-control new-input"
                placeholder="@handle"
                value={formData.instagram}
                name="instagram"
                onChange={handleInputChange}
              />
              {validator.current.message(
                'instagram',
                formData.instagram,
                'required'
              )}
            </div>
            <h3 className="heading-all pt-5">Address</h3>
            <div className="col-md-6">
              <label htmlFor="address" className="input-heading ">
                Address 1
              </label>
              <input
                type="address"
                className="form-control new-input"
                placeholder="Street Number"
                value={formData.address1}
                name="address1"
                onChange={handleInputChange}
              />
              {validator.current.message(
                'address',
                formData.address1,
                'required'
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="address" className="input-heading ">
                Address 2
              </label>
              <input
                type="address"
                className="form-control new-input"
                placeholder="Apt,#,Unit"
                value={formData.address2}
                name="address2"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="city" className="input-heading ">
                City
              </label>
              <input
                type="address"
                className="form-control new-input"
                placeholder="New York"
                value={formData.city}
                name="city"
                onChange={handleInputChange}
              />
              {validator.current.message('city', formData.city, 'required')}
            </div>
            <div className="col-md-6">
              <label htmlFor="city" className="input-heading ">
                State/Province/Region
              </label>
              <input
                type="address"
                className="form-control new-input"
                placeholder="NY"
                value={formData.state}
                name="state"
                onChange={handleInputChange}
              />
              {validator.current.message('state', formData.state, 'required')}
            </div>
            <div className="col-md-6">
              <label htmlFor="zipCode" className="input-heading ">
                ZIP/Postal Code
              </label>
              <input
                type="address"
                className="form-control new-input"
                placeholder="152123"
                value={formData.zip}
                name="zip"
                onChange={handleInputChange}
              />
              {validator.current.message('zip', formData.zip, 'required')}
            </div>
            <div className="col-md-6">
              <label htmlFor="Name" className="input-heading ">
                Country/Region
              </label>
              {/* <input
                type="address"
                className="form-control new-input"
                placeholder="152123"
              /> */}
              <select
                id="inputState"
                className="form-select new-input form-img mb-2"
                value={formData.country}
                name="country"
                onChange={handleInputChange}
              >
                <option value="">Select Country</option>
                {countryListOption?.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
              </select>
              {validator.current.message(
                'country',
                formData.country,
                'required'
              )}
            </div>

            <h3 className="heading-all pt-5">Payment for Commission</h3>
            <p>
              If your application is accepted, a 2.99% transaction fee will be
              deducted from any PayPal payments paid to you. Additional
              <span className="text-sm ps-1">international fees</span> will be
              deducted from PayPal accounts outside of the US.
              <i>
                Note: These transaction fees are set by PayPal and are subject
                to change.
              </i>
            </p>
            <div className="col-md-6">
              <label htmlFor="email" className="input-heading ">
                PayPal Email
              </label>
              <input
                type="email"
                className="form-control new-input"
                placeholder="bella@email.com"
                value={formData.paypal_email}
                name="paypal_email"
                onChange={handleInputChange}
              />
              {validator.current.message(
                'paypal email',
                formData.paypal_email,
                'required'
              )}
            </div>
            <div className="col-md-6"></div>
            <h3 className="heading-all pt-5 d-none">Select Your Product</h3>
            <div className="col-md-12 d-none">
              <div className="pass-img">
                <img src="../assets/images/productImage-2.png" alt="" />
              </div>
              <div className="check-box-click-img">
                {event?.event_passes &&
                  event?.event_passes?.map((item, index) => (
                    <div
                      className="form-check check-box-items"
                      htmlFor={'pass' + index}
                      key={index}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Radio1"
                        id={'pass' + index}
                        checked={formData.event_pass_id === item.id}
                        onChange={() =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            event_pass_id: item.id,
                          }))
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={'pass' + index}
                      >
                        <p className="ps-2"> {item.name}</p>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-5"></div>
            <h3 className="heading-all pt-5">Social Networks</h3>
            <div className="col-md-6">
              <label htmlFor="Name" className="input-heading ">
                Do you have other social networks? (Optional)
              </label>
              <MultiSelect
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                disableSearch={true}
                hasSelectAll={false}
                selectAllLabel=""
              />
            </div>
            <div className="col-md-6"></div>
            {selected.map((item) => (
              <>
                <div key={item.value} className="col-md-6">
                  <label htmlFor={item.field1} className="input-heading">
                    {item.field1}
                  </label>
                  <input
                    type="text"
                    className="form-control new-input"
                    placeholder="Bella"
                    name={item.name}
                    value={formData[item.value1]}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor={item.field2} className="input-heading">
                    {item.field2}
                  </label>
                  <input
                    type="number"
                    className="form-control new-input"
                    placeholder="10,000"
                    name={item.name2}
                    value={formData[item.value2]}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            ))}

            <div className="col-md-6"></div>
            <h3 className="heading-all pt-5">Additional Information</h3>
            <div className="col-md-12">
              <label htmlFor="Name" className="input-heading ">
                Questions, Comments, or Feedback (Optional)
              </label>
              <textarea
                className="form-control new-input"
                placeholder="Ask us about our brand or this campaign!"
                id="floatingTextarea2"
                style={{ height: '100px' }}
                value={formData.questions}
                name="questions"
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="col-md-12">
              <div className="agreement">
                <h4>Agreement</h4>
                <p className="agreement-text">
                  <span className="a-m-f-w">
                    I acknowledge this campaign’s cash payment is based on
                    COMMISSION ONLY
                  </span>
                  . Along with other forms of non-cash compensation that may be
                  offered for this campaign, if any, which are mentioned under
                  <span className="text-sm f-c-b">
                    How You Get Compensated.
                  </span>
                </p>
                <p>
                  I will complete the requirements after I have received a
                  follow-up notification that the campaign has begun. I accept I
                  will complete all the requirements for this campaign as stated
                  in the{' '}
                  <span className="text-sm f-c-b">
                    campaign contract outline.
                  </span>
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="FirstName" className="input-heading ">
                      Signature
                    </label>
                    <input
                      type="text"
                      className="form-control new-input"
                      placeholder="Bell"
                      value={formData.sign}
                      name="sign"
                      onChange={handleInputChange}
                    />
                    {validator.current.message(
                      'sign',
                      formData.sign,
                      'required'
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="date" className="input-heading ">
                      Date
                    </label>
                    <input
                      type="date"
                      className="form-control new-input"
                      placeholder="Silva"
                      value={formData.signdate}
                      readOnly
                      name="signdate"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-check check-agger">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={termAndCondiotion}
                  id="checkFirst"
                  checked={termAndCondiotion}
                  onChange={(e) => {
                    setTermAndCondiotion(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="checkFirst">
                  I have read and agree to the{' '}
                  <Link to="#">Terms and Conditions,</Link>{' '}
                  <Link to="#">Privacy Policy,</Link> and{' '}
                  <Link to="#">Influencer Agreement</Link>
                </label>
              </div>
              <div className="form-check check-agger">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={agree}
                  id="checkClick"
                  checked={agree}
                  onChange={(e) => {
                    setAgree(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="checkClick">
                  By participating in this campaign, I opt-in to receive texts
                  and email communications about my application status, and
                  contract agreement progress and updates. If at any point I
                  opt-out of these communications I acknowledge I am no longer
                  eligible to participate in the campaig
                </label>
              </div>
            </div>
            <p className="error-form-file">{isError && error}</p>
            <div className="submit-button">
              {/* <p> {isError && error}</p> */}
              <button
                type="submit"
                className={
                  validator.current.allValid() && agree && termAndCondiotion
                    ? 'application-button bg-color-click'
                    : 'application-button bg-inactive-button hover-disable '
                }
              >
                Submit Application
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* <FormLogin /> */}
    </div>
  );
};

export default Application;

import React from 'react';
import PropTypes from 'prop-types';

const UserId = (props) => {
  return (
    <>
      <div className="user-link-instagram">
        <div
          className={
            !props.userName
              ? 'image-cover overlay' + ` ${props.listImage}`
              : 'image-cover' + ` ${props.listImage}`
          }
        >
          <img src={props.userImage} alt="" className="user-image" />
        </div>
        <h3>{props.userName}</h3>
      </div>
    </>
  );
};

export default UserId;

UserId.propTypes = {
  userImage: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  // instagramIcon: PropTypes.string.isRequired,
  // instagramLink: PropTypes.string.isRequired,
  listImage: PropTypes.string.isRequired,
};

import React from 'react';
import MainLayout from '../Layout/MainLayout';
import LinkSinger from '../Components/LinkSinger';
import { Link } from 'react-router-dom';

const Partners = () => {
  return (
    <>
      <MainLayout>
        <div className="hero">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <h2 className="main-heading text-mb-center">Partners</h2>
              </div>

              <div className="partner-logos">
                <Link
                  to="https://forms.monday.com/forms/172f2a340eb643de16db44b34cf76b13?r=use1"
                  target="_blank"
                >
                  <img src="../assets/images/TheBaja.png" alt="TheBaja" />
                </Link>
                <Link
                  to="https://www.instagram.com/jetset_loscabos/"
                  target="_blank"
                >
                  <img
                    src="../assets/images/jetset_loscabos.png"
                    alt="jetset_loscabos"
                  />
                </Link>
                <Link to="https://caboeventplanner.com/" target="_blank">
                  <img
                    src="../assets/images/caboeventplanner.png"
                    alt="caboeventplanner"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="../assets/images/tamarind.jpg"
                    className="tamarind-bg"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <LinkSinger />
      </MainLayout>
    </>
  );
};

export default Partners;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, state } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (state !== 'vip_table') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
};

export default ScrollToTop;

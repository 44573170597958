import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const BodyClassSetter = () => {
  const location = useLocation();

  useEffect(() => {
    // Get the current path from the location object
    const currentPath = location.pathname;

    // Check if the path matches "/opportunity" or "/application"
    if (
      currentPath === '/opportunity' ||
      currentPath === '/application' ||
      currentPath === '/creator-opportunity' ||
      currentPath === '/thank'
    ) {
      // Add your desired class to the body element
      document.body.classList.add('affiliate-body');
    } else {
      // Remove the class if the path doesn't match
      document.body.classList.remove('affiliate-body');
    }
  }, [location.pathname]);

  // This component doesn't render anything in the DOM
  return null;
};

export default BodyClassSetter;

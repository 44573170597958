import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="last-linking">
          <div className="footer-logo">
            <img src="../assets/images/star_ico.png" alt="" />
          </div>
          <div className="social-icon">
            {/* <Link to="">
              <img src="../assets/images/twitter.svg" alt="" />
            </Link> */}
            <Link
              to="https://www.instagram.com/consciouskissofficial/"
              target="_blank"
            >
              <img src="../assets/images/instagram.svg" alt="" />
            </Link>
            {/* <Link to="">
              <img src="../assets/images/twitch.svg" alt="" />
            </Link> */}
          </div>
          <p>© 2024 Conscious Kiss</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;

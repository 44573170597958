import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { useNavigate } from 'react-router-dom';
import { API_GET_ACTIVE_EVENT } from '../config/Endpoints';
import Parser from 'html-react-parser';
import moment from 'moment';
import LinkSinger from '../Components/LinkSinger';
import { generateAvailableDates, getClassName } from '../utils/helper';

const EventPasses = () => {
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState('March 25 - Friday');
  const [selectedDate, setSelectedDate] = useState('March 25 - Friday');
  const [availableDates, setAvailableDates] = useState([]);

  const navigate = useNavigate();

  const fetchEvent = async () => {
    setLoading(true);
    await fetch(API_GET_ACTIVE_EVENT, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setEvent(result.data);
      })
      .catch((error) => console.log('error', error));
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  useEffect(() => {
    if (event?.event_passes) {
      const oneDayPasses = event.event_passes.filter(
        (item) => item.type === 'ONE_DAY'
      );
      if (oneDayPasses.length > 0) {
        const dates = generateAvailableDates(
          oneDayPasses[0].start_date,
          oneDayPasses[0].end_date
        );
        setAvailableDates(dates);
        setSelectedDay(dates[0]?.actualDate);
        setSelectedDate(dates[0]?.formatDate);
      }
    }
  }, [event]);

  const handleBuyNow = (data, payment_type) => {
    let obj = {};
    if (data?.type === 'ONE_DAY') {
      obj.id = data?.id;
      obj.name = data?.name;
      obj.selectedDate = selectedDay;
      obj.location = data?.location;
      obj.price = data.has_special_price ? data.special_price : data?.price;
      obj.type = data?.type;
      obj.start_date = data?.start_date;
      obj.end_date = data?.end_date;
      obj.payment_mode = payment_type;
      obj.has_special_price = data.has_special_price;
      obj.actual_price = data?.price;
    } else {
      obj.id = data?.id;
      obj.name = data?.name;
      obj.start_date = data?.start_date;
      obj.end_date = data?.end_date;
      obj.location = data?.location;
      obj.price = data.has_special_price ? data.special_price : data?.price;
      obj.type = data?.type;
      obj.payment_mode = payment_type;
      obj.has_special_price = data.has_special_price;
      obj.actual_price = data?.price;
    }

    navigate('/checkout', {
      state: {
        data: obj,
      },
    });
  };

  useEffect(() => {
    availableDates.map((item) => {
      if (item?.actualDate === selectedDay) {
        setSelectedDate(item.formatDate);
      }
    });
  }, [selectedDay]);

  return (
    <>
      <MainLayout>
        {loading && <div className="text-center fs-5 p-5">Loading....</div>}
        {!loading && event && (
          <>
            <section className="hero">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h2 className="main-heading">Event Pass</h2>
                  </div>
                  <div className="col-md-6">
                    <div className="music-cover">
                      <h3>{Parser(`${event?.name}`)}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="event-pass">
              <div className="container-fluid">
                <div className="row g-0">
                  <div className="card-items-list">
                    {event?.event_passes &&
                      event?.event_passes
                        ?.filter((pass) => pass.status === 1)
                        .map((item, index) => (
                          <div
                            className={`card-select mt-3 mb-5  ${
                              event?.event_passes?.length === 1 &&
                              'only-one-card'
                            }
                          ${event?.event_passes?.length === 4 && 'four-card'}`}
                            key={index}
                          >
                            <div className={` ${getClassName(index)}`}>
                              <h3>
                                {item?.name}{' '}
                                {item?.type === 'THREE_DAY' && <></>}
                              </h3>
                              <div className="number-list number-height ">
                                {item?.type !== 'ONE_DAY' && (
                                  <div className="list">
                                    <div className="month width">
                                      <h4 className="pb-set">
                                        {moment(item?.start_date).format(
                                          'MMM DD '
                                        )}
                                        {/* —{moment(item?.end_date).format(' DD')} */}
                                      </h4>
                                      <h4>
                                        {moment(item?.start_date).format(
                                          'ddd '
                                        )}
                                        {/* —{' '}
                                        {moment(item?.end_date).format(' ddd')} */}
                                      </h4>
                                    </div>
                                    <div className="month width-two">
                                      <h4>{Parser(`${item?.location}`)}</h4>
                                    </div>
                                  </div>
                                )}
                                {item?.type === 'ONE_DAY' && (
                                  <>
                                    <div className="list">
                                      <div className="month">
                                        <h4>{selectedDate}</h4>
                                      </div>
                                      <div className="month">
                                        <h4>{Parser(`${item?.location}`)}</h4>
                                      </div>
                                    </div>
                                    <div className="date-select-user-pro">
                                      <hr />
                                      <label htmlFor="text">Select Date</label>
                                      <select
                                        name="date"
                                        id="date"
                                        className="form-select form-control w-100"
                                        value={selectedDay}
                                        onChange={(e) =>
                                          setSelectedDay(e.target.value)
                                        }
                                      >
                                        {availableDates.map((date, index) => (
                                          <option
                                            key={index}
                                            value={date.actualDate}
                                          >
                                            {date.formatDate}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </>
                                )}
                                <div>
                                  {item.has_special_price && (
                                    <>
                                      <p className="price-text">
                                        USD ${item?.special_price}
                                      </p>
                                    </>
                                  )}
                                  {/* <p
                                    className={
                                      !item.has_special_price
                                        ? 'price-text'
                                        : 'text-muted'
                                    }
                                  >
                                    {item.has_special_price && <span>Was</span>}{' '}
                                    USD ${item?.price}
                                  </p> */}

                                  {item?.available_quantity <= 0 ? (
                                    <span className="bg-danger rounded p-2 fw-bolder">
                                      SOLD OUT
                                    </span>
                                  ) : (
                                    <>
                                      <button
                                        onClick={() =>
                                          handleBuyNow(item, 'pills-home')
                                        }
                                        className="theme-button"
                                      >
                                        BUY NOW
                                      </button>
                                      <p className="mb-0 py-2">or</p>
                                      <a
                                        className="referral-code-css"
                                        onClick={() =>
                                          handleBuyNow(item, 'pills-profile')
                                        }
                                      >
                                        Pay with Klarna
                                      </a>
                                      <h6 className="passes-left">
                                        {/* {item?.name}{' '} */}
                                        {/* {item?.type === 'THREE_DAY' && (
                                          <span>(Only a few passes left)</span>
                                        )} */}
                                      </h6>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </section>
            <section className="event-text">
              {Parser(`${event?.description}`)}
            </section>
            <LinkSinger />
          </>
        )}
        {!loading && event === null && (
          <div className="coming-soon">
            <h4 className="closing">Coming Soon...</h4>
          </div>
        )}
      </MainLayout>
    </>
  );
};

export default EventPasses;

import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const MainLayout = ({ children, className }) => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <div className={`wrapper-body ${className}`}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default MainLayout;

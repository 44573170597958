import React, { useEffect, useState } from 'react';
import PhoneNumberField from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  API_GET_ACTIVE_EVENT,
  API_VIP_PURCHASE,
  API_VIP_TABLE,
} from '../config/Endpoints';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { ThreeDots } from 'react-loader-spinner';

const VIPTableModal = ({
  selectedTableId,
  selectedEvent,
  isVIP,
  setIsVIP,
  selectedDate,
}) => {
  const [errorMsg, SetErrorMsg] = useState('');
  const [eventId, setEventId] = useState('');
  const [isError, SetIsError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    groupSize: '',
    day: '',
    ticketStatus: '',
    reservation: 'ReserveNow',
    phoneNumber: '',
    zipCode: '',
  });
  const [errors, setErrors] = useState({});
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    fetchEvent();
  }, []);

  useEffect(() => {}, [isVIP]);

  const fetchEvent = async () => {
    await fetch(API_GET_ACTIVE_EVENT, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setEventId(result?.data?.id);
      })
      .catch((error) => console.log('error', error));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    try {
      const phoneNumberObj = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObj ? phoneNumberObj.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumber(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        var formdata = new FormData();
        formdata.append('first_name', formData.firstName);
        formdata.append('last_name', formData.lastName);
        formdata.append('email', formData.email);
        formdata.append('phone', formData.phoneNumber);
        formdata.append('group_size', formData.groupSize);
        formdata.append('day', selectedDate);
        formdata.append('ticket_status', formData.ticketStatus);
        formdata.append('event_id', eventId);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch(API_VIP_TABLE, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result?.status) {
              setIsVIP(true);
              setFormData({
                firstName: '',
                lastName: '',
                email: '',
                groupSize: '',
                day: '',
                ticketStatus: '',
                phoneNumber: '',
                zipCode: '',
                ...formData,
              });
              setErrors({});
            } else {
              SetIsError(true);
              if (result?.message?.errors?.email) {
                SetErrorMsg(result?.message?.errors?.email);
              } else {
                SetErrorMsg('Something went wrong. Please try again!');
              }
              setTimeout(() => {
                SetIsError(false);
              }, 4000);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
        setIsLoading(false);
      });
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (elements == null) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    if (error) {
      SetIsError(true);
      SetErrorMsg('Something went wrong. Please try again!');
      setTimeout(() => {
        SetIsError(false);
      }, 6000);
      setIsLoading(false);
    }
    if (paymentMethod) {
      var formdata = new FormData();
      formdata.append('first_name', formData.firstName);
      formdata.append('last_name', formData.lastName);
      formdata.append('email', formData.email);
      formdata.append('phone', formData.phoneNumber);
      formdata.append('group_size', formData.groupSize);
      formdata.append('day', selectedDate);
      formdata.append('ticket_status', formData.ticketStatus);
      formdata.append('event_pass_id', selectedEvent?.id);
      formdata.append('table_id', selectedTableId);
      formdata.append('payment_method_id', paymentMethod.id);
      formdata.append('no_of_days', selectedEvent?.dayCount);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      fetch(API_VIP_PURCHASE, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status) {
            setIsVIP(true);
            setFormData({
              firstName: '',
              lastName: '',
              email: '',
              groupSize: '',
              day: '',
              ticketStatus: '',
              phoneNumber: '',
              zipCode: '',
              ...formData,
            });
            setErrors({});
          } else {
            SetIsError(true);
            if (result?.message?.errors?.email) {
              SetErrorMsg(result?.message?.errors?.email);
            } else {
              SetErrorMsg(
                result?.message
                  ? result?.message
                  : 'Something went wrong. Please try again!'
              );
            }
            setTimeout(() => {
              SetIsError(false);
            }, 4000);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const handleInputKeyPress = (event) => {
    const maxLength = 16; // Maximum length requirement
    const inputValue = event.target.value;

    if (
      inputValue.length >= maxLength &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      event.preventDefault();
    }
  };

  const inputOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#dd9a42',
        fontFamily: 'inherit',
        '::placeholder': {
          color: '#ffffff80',
        },
        outline: '2px solid blue',
      },
    },
  };

  return (
    <div className="">
      {!isVIP && selectedTableId && (
        <form
          onSubmit={
            formData.reservation === 'ReserveNow'
              ? handlePaymentSubmit
              : handleSubmit
          }
        >
          <div className="modal-list">
            {isError && (
              <h6 className="main-heading text-heading text-mb-center text-center">
                {errorMsg}
              </h6>
            )}
            <div className="row g-0">
              {/* <div className="col-lg-6 d-none">
                <h6 className="text-heading size-increase py-2">
                  Which day do you want a VIP table for?
                </h6>
                <div className="radio-check-list">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="day"
                      id="check1"
                      value="Fri"
                      checked={formData.day === 'Fri'}
                      onChange={handleInputChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="check1">
                      Fri, Day 1
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="day"
                      id="check2"
                      value="Sat"
                      checked={formData.day === 'Sat'}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="check2">
                      Sat, Day 2
                    </label>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6">
                <h6 className="text-heading size-increase py-2 ">
                  Do you already have a ticket?
                </h6>
                <div className="radio-check-list mb-5">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="First"
                      value="Yes"
                      name="ticketStatus"
                      checked={formData.ticketStatus === 'Yes'}
                      onChange={handleInputChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="First">
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="Second"
                      value="No"
                      name="ticketStatus"
                      checked={formData.ticketStatus === 'No'}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="Second">
                      No
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="Third"
                      value="Waitlist"
                      name="ticketStatus"
                      checked={formData.ticketStatus === 'Waitlist'}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="Third">
                      No, but I’m on the waitlist
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="radio-check-list mb-5">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="Reserve_Now"
                      value="ReserveNow"
                      name="reservation"
                      checked={formData.reservation === 'ReserveNow'}
                      onChange={handleInputChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="Reserve_Now">
                      Reserve Now
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="Talk"
                      value="TalkToConsciousKissCoordinator"
                      name="reservation"
                      checked={
                        formData.reservation ===
                        'TalkToConsciousKissCoordinator'
                      }
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="Talk">
                      Talk to Conscious Kiss Coordinator
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="list-form">
                  <input
                    type="text"
                    className="form-control b-b-r border-right-remove"
                    id="fName"
                    placeholder="First Name*"
                    name="firstName"
                    value={formData.firstName}
                    required
                    onChange={handleInputChange}
                    onKeyPress={(e) => {
                      const keyCode = e.charCode || e.keyCode;
                      // Allow only numerics, letters, and spaces
                      if (
                        !(
                          (keyCode >= 48 && keyCode <= 57) || // Numerics
                          (keyCode >= 65 && keyCode <= 90) || // Uppercase letters
                          (keyCode >= 97 && keyCode <= 122) || // Lowercase letters
                          keyCode === 32
                        )
                      ) {
                        // Space
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      // Prevent pasting of special characters
                      const pastedText = e.clipboardData.getData('text/plain');
                      if (!/^[a-zA-Z0-9\s]+$/.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="list-form">
                  <input
                    type="text"
                    className="form-control b-b-r"
                    id="lName"
                    placeholder="Last Name*"
                    name="lastName"
                    value={formData.lastName}
                    required
                    onChange={handleInputChange}
                    onKeyPress={(e) => {
                      const keyCode = e.charCode || e.keyCode;
                      // Allow only numerics, letters, and spaces
                      if (
                        !(
                          (keyCode >= 48 && keyCode <= 57) || // Numerics
                          (keyCode >= 65 && keyCode <= 90) || // Uppercase letters
                          (keyCode >= 97 && keyCode <= 122) || // Lowercase letters
                          keyCode === 32
                        )
                      ) {
                        // Space
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      // Prevent pasting of special characters
                      const pastedText = e.clipboardData.getData('text/plain');
                      if (!/^[a-zA-Z0-9\s]+$/.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="list-form">
                  <input
                    type="email"
                    className="form-control b-b-r border-right-remove"
                    id="email"
                    placeholder="Email*"
                    name="email"
                    value={formData.email}
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="list-form">
                  <PhoneNumberField
                    name="phoneNumber"
                    international
                    countryCallingCodeEditable={false}
                    placeholder="Phone/Whatsapp*"
                    value={formData.phoneNumber}
                    onChange={(value) =>
                      handlePhoneNumberChange({
                        target: { name: 'phoneNumber', value },
                      })
                    }
                    defaultCountry="US"
                    onKeyPress={handleInputKeyPress}
                    error={!isValidPhoneNumber}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="list-form">
                  <select
                    id="inputState"
                    className={`form-select form-control ${
                      formData.reservation === 'ReserveNow' && 'b-b-r'
                    }  m-b-b-r border-right-remove`}
                    name="groupSize"
                    value={formData.groupSize}
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">Group size*</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="list-form">
                  <input
                    type="text"
                    className={`form-control ${
                      formData.reservation === 'ReserveNow' && 'b-b-r'
                    } b-t-r m-b-b-t-s`}
                    id="zipCode"
                    placeholder="Zip Code*"
                    name="zipCode"
                    value={formData.zipCode}
                    required
                    onChange={handleInputChange}
                    maxLength="5"
                    onKeyPress={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              {formData.reservation === 'ReserveNow' && (
                <>
                  <div className="col-lg-6">
                    <div className="list-form">
                      <CardNumberElement
                        className="stripe-input border-right-remove"
                        options={inputOptions}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="list-form">
                      <CardExpiryElement
                        className="stripe-input b-r-r m-b-r b-r-a m-b-b-r"
                        options={inputOptions}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="list-form">
                      <CardCvcElement
                        className="stripe-input m-b-r m-b-b-r"
                        options={inputOptions}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="instruction">
                <h4>Seating Capacity</h4>
                <p>The maximum seating capacity for each table is below.</p>
                <ul>
                  <li>1.5k - 6 people</li>
                  <li>3k - 6 people</li>
                  <li>5k - 10 people</li>
                  <li>6k - 10 people</li>
                </ul>
                <p>Please plan your party size accordingly.</p>
              </div>
            </div>
          </div>
          {errors.phoneNumber && (
            <div className="text-center text-danger">{errors.phoneNumber}</div>
          )}
          {errors.email && (
            <div className="text-danger mb-3 text-center">{errors.email}</div>
          )}
          <div className="col-lg-12 px-md-5 px-0">
            {isError && <h5 className="error text-center">{errorMsg}</h5>}
          </div>
          {!isValidPhoneNumber && formData.phoneNumber?.length > 4 && (
            <h6 className="error text-center">Invalid phone number</h6>
          )}
          <div className="modal-footer border-0 justify-content-center">
            {formData.reservation === 'TalkToConsciousKissCoordinator' && (
              <button type="submit" className="theme-button px-5">
                {isLoading ? (
                  <ThreeDots
                    height="20"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ display: 'contents' }}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  'Submit'
                )}
              </button>
            )}

            {formData.reservation === 'ReserveNow' && (
              <button
                type="submit"
                className={
                  (!stripe || !elements || !isValidPhoneNumber) &&
                  formData.reservation === 'ReserveNow'
                    ? 'theme-button px-5 disable-button'
                    : 'theme-button px-5'
                }
                disabled={
                  (!stripe || !elements || !isValidPhoneNumber) &&
                  formData.reservation !== 'ReserveNow'
                }
              >
                {isLoading ? (
                  <ThreeDots
                    height="20"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ display: 'contents' }}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  'Continue to Payment'
                )}
              </button>
            )}
          </div>
        </form>
      )}
      {isVIP && (
        <div className="submit-message">
          <div className="message">
            {formData.reservation === 'ReserveNow' && (
              <div>
                <p>
                  Congrats! Your reservation is complete! 🎉 You will receive an
                  email with more information shortly. See you soon!
                  {/* <a href="mailto:admin@consciouskissofficial.com">
                    {' '}
                    admin@consciouskissofficial.com
                  </a>{' '}
                  (Subject - VIP Tables Question). */}
                </p>
                {/* <h5 className="text-heading mt-3">Can’t wait to see you!</h5> */}
              </div>
            )}
            {formData.reservation === 'TalkToConsciousKissCoordinator' && (
              <div>
                <p>
                  Thank you for your submission, one of our VIP coordinators
                  will contact you soon to get connected.
                  {/* <a href="mailto:admin@consciouskissofficial.com">
                    {' '}
                    admin@consciouskissofficial.com
                  </a>{' '}
                  (Subject - VIP Tables Question). */}
                </p>
                {/* <h5 className="text-heading mt-3">Can’t wait to see you!</h5> */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VIPTableModal;

const emailRegExp = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+com$/;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string().required(''),
});

VIPTableModal.propTypes = {
  selectedTableId: PropTypes.string,
  selectedEvent: PropTypes.object,
  isVIP: PropTypes.bool,
  setIsVIP: PropTypes.func,
  selectedDate: PropTypes.string,
};

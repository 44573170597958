import moment from 'moment';

//function to generate the list of available dates
export const generateAvailableDates = (startDate, endDate) => {
  const datesArray = [];
  let formatStartDate = moment(startDate).format('YYYY-MM-DD');
  let formatEndDate = moment(endDate).format('YYYY-MM-DD');
  let currentDate = moment(formatStartDate);

  while (currentDate <= moment(formatEndDate)) {
    datesArray.push({
      actualDate: currentDate.format('YYYY-MM-DD HH:mm:ss'),
      formatDate: currentDate.format('MMMM DD - ddd'),
    });
    currentDate.add(1, 'day');
  }

  return datesArray;
};

//function to generate the list of available dates
export const generateAvailableDatesVIP = (startDate, endDate) => {
  const datesArray = [];
  let formatStartDate = moment(startDate).format('YYYY-MM-DD');
  let formatEndDate = moment(endDate).format('YYYY-MM-DD');
  let currentDate = moment(formatStartDate);

  while (currentDate < moment(formatEndDate)) {
    datesArray.push({
      actualDate: currentDate.format('YYYY-MM-DD HH:mm:ss'),
      formatDate: currentDate.format('MMM DD, dddd'),
    });
    currentDate.add(1, 'day');
  }
  return datesArray;
};

// Function to get the actual date after selection
export const getActualDate = (selectedOption) => {
  const formattedDate = moment(selectedOption, 'MMMM DD - ddd').format(
    'YYYY-MM-DD HH:mm:ss'
  );
  return formattedDate;
};

export const getClassName = (index) => {
  if (index % 2 === 0) {
    return 'card-items dark';
  } else {
    return 'card-items';
  }
};

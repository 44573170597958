import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import Payment from './payment/Payment';
import Parser from 'html-react-parser';
import moment from 'moment';
import Completion from './payment/Completion';
import { useLocation } from 'react-router-dom';
import App from './KlarnaPayment/App';
import { generateAvailableDates } from '../utils/helper';
import { API_KLARNA_INTENT } from '../config/Endpoints';
import { getLocalData } from '../utils/storage';

import DiscountReferral from '../Components/DiscountReferral';

function CheckOut() {
  const { state } = useLocation();
  const { data } = state || {};
  const [checkPayment, setCheckPayment] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [couponCodeState, setCouponCodeState] = useState('');
  const [totalPrice, setTotalPrice] = useState(data?.price ? data?.price : 0);
  const [previousAmount, setPreviousAmount] = useState(0);
  const [selectedDay, setSelectedDay] = useState(
    data?.selectedDate ? data?.selectedDate : ''
  );
  const [paymentMethodSelected, setPaymentMethodSelected] = useState('Card');
  const [availableDates, setAvailableDates] = useState([]);
  const [clientSecret, setClientSecret] = useState('');
  const [intendData, setIntendData] = useState({});
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(
    data?.actual_price ? data?.actual_price - data?.price : 0
  );
  const [referralCode, setReferralCode] = useState(
    getLocalData('referralCode') ? getLocalData('referralCode') : ''
  );
  const [isCouponError, setIscouponError] = useState();
  const [isCouponSuccess, setIsCouponSuccess] = useState();
  const [isAppliedCouponCode, setIsAppliedCouponCode] = useState(false);
  const [activeTab, setActiveTab] = useState(
    data?.payment_mode ? data?.payment_mode : 'pills-home'
  );

  const [checkOutStep, setCheckOutStep] = useState('step-1');

  const calculateDiscount = () => {
    let discountPercentage = 0.2; //20% discount
    let discountAmount = 0;
    setPreviousAmount(totalPrice);
    discountAmount = totalPrice * discountPercentage;
    let chargeAmount = totalPrice - discountAmount;
    setTotalPrice(chargeAmount);
    if (data?.has_special_price) {
      let discountAmountCal = data?.actual_price * quantity - chargeAmount;
      setDiscount(discountAmountCal);
    } else {
      setDiscount(discountAmount);
    }
  };

  const removeDiscount = (e) => {
    e.preventDefault();
    setTotalPrice(data?.price * quantity);
    if (data?.has_special_price) {
      let discountAmountCal =
        data?.actual_price * quantity - data?.price * quantity;
      setDiscount(discountAmountCal);
    } else {
      setDiscount(0);
    }
    setPreviousAmount(0);
  };

  const handleQuantity = (e) => {
    setQuantity(e.target.value);
    setPreviousAmount(0);
    setIsAppliedCouponCode(false);
    setCouponCode('');
    let runtime_qunatity = e.target.value;
    if (data?.has_special_price) {
      setDiscount(
        data?.actual_price * runtime_qunatity - data?.price * runtime_qunatity
      );
    } else {
      setDiscount(0);
    }
  };

  const handleCouponCode = (e) => {
    e.preventDefault();
    const couponCodeString = 'conscious20';
    // Update the couponCode state
    setCouponCode(e.target.value);
    setIscouponError('');
    if (e.target.value.toLowerCase() === couponCodeString.toLowerCase()) {
      setIsAppliedCouponCode(true);
      setIsCouponSuccess('Coupon code applied successfully!');
      calculateDiscount();
      if (activeTab === 'pills-profile') {
        klarnaAPI(e.target.value);
      }
    } else {
      setIscouponError('Invalid coupon code');
      setTimeout(() => {
        setIscouponError('');
      }, 4000);
    }
  };

  const handleRemoveCouponCode = (e) => {
    setIsAppliedCouponCode(false);
    removeDiscount(e);
    setCouponCode('');
  };

  useEffect(() => {
    setTotalPrice(data?.price * quantity);
  }, [quantity]);

  useEffect(() => {
    if (data) {
      if (data.type === 'ONE_DAY') {
        const dates = generateAvailableDates(data.start_date, data.end_date);
        setAvailableDates(dates);
        setSelectedDay(data?.selectedDate);
      }
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'pills-profile') {
      setClientSecret('');
      var formdata = new FormData();
      formdata.append('event_pass_id', data?.id);
      formdata.append('referral_code', referralCode);
      formdata.append('coupon', couponCode);
      formdata.append('selected_date', selectedDay);
      formdata.append('quantity', quantity);
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };
      fetch(API_KLARNA_INTENT, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setIntendData(data.data.details);
          setClientSecret(data?.data?.intent?.client_secret);
        });
    }
  }, [quantity, activeTab]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const klarnaAPI = (e) => {
    setClientSecret('');
    var formdata = new FormData();
    formdata.append('event_pass_id', data?.id);
    formdata.append('referral_code', referralCode);
    formdata.append('coupon', e === couponCode ? couponCode : e);
    formdata.append('selected_date', selectedDay);
    formdata.append('quantity', quantity);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    fetch(API_KLARNA_INTENT, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setIntendData(data.data.details);
        setClientSecret(data?.data?.intent?.client_secret);
      });
  };

  return (
    <MainLayout>
      <div className="hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="main-heading">Event Checkout</h2>
            </div>
            <div className="col-md-6">
              <div className="music-cover checkout-page">
                <h3>{data?.name}</h3>
              </div>
            </div>
            <div className="col-12">
              <div className="price-new">
                <ul>
                  {data?.type !== 'ONE_DAY' && (
                    <li>
                      {moment(data?.start_date).format('MMM DD ')}
                      {/* —{' '}
                      {moment(data?.end_date).format(' DD')} */}
                    </li>
                  )}
                  {data?.type !== 'ONE_DAY' && (
                    <li className="list-set">
                      {moment(data?.start_date).format('ddd ')}
                      {/* —{' '}
                      {moment(data?.end_date).format(' ddd')} */}
                    </li>
                  )}
                  {data?.type === 'ONE_DAY' && (
                    <select
                      name="date"
                      id="date"
                      className="form-select form-control w-100 input-bb"
                      value={selectedDay}
                      onChange={(e) => setSelectedDay(e.target.value)}
                    >
                      {availableDates.map((date, index) => (
                        <option key={index} value={date.actualDate}>
                          {date.formatDate}
                        </option>
                      ))}
                    </select>
                  )}
                </ul>
                <ul>
                  <li>{Parser(`${data?.location}`)}</li>
                </ul>
                <ul>
                  <li className="amount-checkout">
                    {/* Price: */}
                    {previousAmount !== 0 && !data?.has_special_price && (
                      <strike className="fw-bolder text-secondary pe-3">
                        USD ${previousAmount}
                      </strike>
                    )}
                    USD ${totalPrice}
                    {/* {data?.has_special_price && (
                      <p>Was USD ${data?.actual_price * quantity}</p>
                    )} */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="checkout">
          <div className="container">
            {!checkPayment && (
              <div className="row">
                <div className="col-lg-7"></div>
                <div className="col-lg-5">
                  <DiscountReferral
                    quantity={quantity}
                    handleQuantity={handleQuantity}
                    discount={discount}
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    isAppliedCouponCode={isAppliedCouponCode}
                    handleCouponCode={handleCouponCode}
                    handleRemoveCouponCode={handleRemoveCouponCode}
                    referralCode={referralCode}
                    setReferralCode={setReferralCode}
                    isCouponSuccess={isCouponSuccess}
                    isCouponError={isCouponError}
                    checkOutStep={checkOutStep}
                    setCheckOutStep={setCheckOutStep}
                    hasSpecialPrice={data?.has_special_price}
                  />
                </div>
              </div>
            )}
            {checkOutStep === 'step-2' && (
              <div className="row">
                <div className="col-md-4">
                  {!checkPayment && (
                    <p className="payment-mode">Select payment mode</p>
                  )}
                </div>
                <div className="col-md-8">
                  {!checkPayment && (
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item border-r" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === 'pills-home' ? 'active' : ''
                          }`}
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected={activeTab === 'pills-home'}
                          onClick={() => handleTabClick('pills-home')}
                        >
                          Card
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === 'pills-profile' ? 'active' : ''
                          }`}
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected={activeTab === 'pills-profile'}
                          onClick={() => handleTabClick('pills-profile')}
                        >
                          Klarna
                        </button>
                      </li>
                    </ul>
                  )}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab === 'pills-home' ? 'show active' : ''
                      }`}
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      {!checkPayment && (
                        <Payment
                          setCheckPayment={setCheckPayment}
                          eventId={data?.id}
                          selectedPass={data}
                          setQuantity={handleQuantity}
                          quantity={quantity}
                          totalPrice={totalPrice}
                          calculateDiscount={calculateDiscount}
                          removeDiscount={removeDiscount}
                          setSelectedDay={setSelectedDay}
                          selectedDay={selectedDay}
                          setPaymentMethodSelected={setPaymentMethodSelected}
                          paymentMethodSelected={paymentMethodSelected}
                          setCouponCodeState={setCouponCodeState}
                          setReferralCode={setReferralCode}
                          referralCode={referralCode}
                          couponCode={couponCode}
                        />
                      )}
                      {checkPayment && <Completion />}
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === 'pills-profile' ? 'show active' : ''
                      }`}
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-lg-12">
                        {clientSecret && (
                          <App
                            couponCodeState={couponCodeState}
                            clientSecret={clientSecret}
                            intendData={intendData}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default CheckOut;

function Completion() {
  return (
    <div className="thankyou pt-5 text-center text-md-start">
      <h3 className="text-success text-mb-center">Payment successful!</h3>
      <h4 className="main-heading text-mb-center">Thank you! 🎉</h4>
      <p className="text-heading text-mb-center">
        We have sent you a confirmation email containing all the details of your
        ticket.
      </p>
      <a href="/line-up" className="theme-button mt-4">
        Checkout LineUp
      </a>
    </div>
  );
}

export default Completion;

import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Index from './Pages/Index';
import PlanVisit from './Pages/PlanVisit';
import EventPasses from './Pages/EventPasses';
import LineUp from './Pages/LineUp';
import ScrollToTop from './utils/ScrollToTop';
import CheckOut from './Pages/CheckOut';
import WeekendGuide from './Pages/WeekendGuide';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsConditions from './Pages/TermsConditions';
import Referral from './Pages/Referral';
import Partners from './Pages/Partners';
import Opportunity from './Pages/Affiliate-Partner/Opportunity.jsx';
import Application from './Pages/Affiliate-Partner/Application';
import BodyClassSetter from './Components/BodyClassSetter';
import CreatorOpportunity from './Pages/Affiliate-Partner/CreatorOpportunity';
import Thank from './Pages/Affiliate-Partner/Thank';
import AfterCheckout from './Pages/KlarnaPayment/AfterCheckout.jsx';
import ChooseTable from './Pages/ChooseTable.jsx';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const TRACKING_ID = 'G-JNTV9T3JPJ'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    const currentPath = window.location.pathname + window.location.search;
    if (currentPath === '/' || currentPath === '/event-passes') {
      ReactGA.pageview(currentPath);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <BodyClassSetter />
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Index />} />
          <Route path="/plan-visit" element={<PlanVisit />} />
          <Route path="/event-passes" element={<EventPasses />} />
          <Route path="/line-up" element={<LineUp />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/weekend-guide" element={<WeekendGuide />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsConditions />} />
          <Route path="/ref" element={<Referral />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/opportunity" element={<Opportunity />} />
          <Route path="/application" element={<Application />} />
          <Route path="/creator-opportunity" element={<CreatorOpportunity />} />
          <Route path="/thank" element={<Thank />} />
          <Route path="/klarna-payment" element={<AfterCheckout />} />
          <Route path="/choose-table" element={<ChooseTable />} />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
    </>
  );
}

export default App;

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { API_POST_PURCHASE } from '../../config/Endpoints';
import { ThreeDots } from 'react-loader-spinner';
import 'react-phone-number-input/style.css';
import PhoneNumberField from 'react-phone-number-input';
// import PhoneNumberVerfiy from './PhoneNumberVerfiy';
// import { Link } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { deleteLocalData, getLocalData } from '../../utils/storage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const inputOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#dd9a42',
      fontFamily: 'inherit',
      '::placeholder': {
        color: '#ffffff80',
      },
      outline: '2px solid blue',
    },
  },
};
const CheckoutForm = ({
  setCheckPayment,
  eventId,
  // setQuantity,
  quantity,
  phoneNumber,
  setPhoneNumber,
  // SetIsPhoneNumberVerified,
  // calculateDiscount,
  // removeDiscount,
  // selectedPass,
  // setSelectedDay,
  selectedDay,
  // setPaymentMethodSelected,
  paymentMethodSelected,
  setCouponCodeState,
  setReferralCode,
  referralCode,
  couponCode,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    zipCode: '',
    email: '',
    quantity: quantity ? quantity : 1,
    couponCode: '',
    referralCode: getLocalData('referralCode')
      ? getLocalData('referralCode')
      : '',
  });
  const [errorMsg, SetErrorMsg] = useState('');
  const [isError, SetIsError] = useState(false);
  const [isLoading, SetIsLoading] = useState(false);
  const [isAppliedCouponCode, setIsAppliedCouponCode] = useState(false);
  const [isCouponError, setIscouponError] = useState();
  const [isCouponSuccess, setIsCouponSuccess] = useState();
  // const [isReferral, setIsReferral] = useState(
  //   getLocalData('referralCode') ? true : false
  // );

  const stripe = useStripe();
  const elements = useElements();
  const [isMobile, setIsMobile] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [cardBrandList] = useState([
    'visa',
    'mastercard',
    'amex',
    'discover',
    'diners',
  ]);
  // const [paymentMethodSelected, setPaymentMethodSelected] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'couponCode') {
      setCouponCodeState(value);
    }
    if (name === 'referralCode') {
      setReferralCode(value);
    }
    setIsCouponSuccess('');
    setIscouponError('');
  };

  useEffect(() => {
    setIsAppliedCouponCode(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      quantity: quantity,
      couponCode: '',
    }));
  }, [quantity]);

  // useEffect(() => {
  //   if (selectedPass) {
  //     if (selectedPass.type === 'ONE_DAY') {
  //       const dates = generateAvailableDates(
  //         selectedPass.start_date,
  //         selectedPass.end_date
  //       );
  //       setAvailableDates(dates);
  //       setSelectedDay(selectedPass?.selectedDate);
  //     }
  //   }
  // }, [selectedPass]);
  useEffect(() => {
    const handleResize = () => {
      // Check the viewport width to determine if it's a mobile view
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    // Initial check when the component mounts
    handleResize();
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {}, [selectedDay]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    SetIsLoading(true);
    if (elements == null) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    if (error) {
      SetIsError(true);
      SetErrorMsg('Something went wrong. Please try again!');
      setTimeout(() => {
        SetIsError(false);
      }, 6000);
      SetIsLoading(false);
    }
    if (paymentMethod) {
      var formdata = new FormData();
      formdata.append('payment_method_id', paymentMethod.id);
      formdata.append('name', formData.name);
      formdata.append('email', formData.email);
      formdata.append('phone', phoneNumber);
      formdata.append('quantity', quantity);
      formdata.append('coupon', couponCode);
      formdata.append('referral_code', referralCode);
      formdata.append('selected_date', selectedDay ? selectedDay : '');

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      fetch(API_POST_PURCHASE + `${eventId}/purchase`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let response = JSON.parse(result);
          if (response?.status) {
            setCheckPayment(true);
            SetIsLoading(false);
            deleteLocalData('referralCode');
          } else {
            SetIsError(true);
            SetErrorMsg(response?.message);
            setTimeout(() => {
              SetIsError(false);
            }, 6000);
            SetIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          SetIsError(true);
          SetErrorMsg('Something went wrong. Please try again!');
          setTimeout(() => {
            SetIsError(false);
          }, 6000);
          SetIsLoading(false);
        });
    }
  };

  // const handleChangeNumber = (e) => {
  //   e.preventDefault();
  //   SetIsPhoneNumberVerified(false);
  // };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    // setIsValidPhoneNumber(true);
    try {
      const phoneNumberObj = parsePhoneNumberFromString(value);
      setIsValidPhoneNumber(phoneNumberObj ? phoneNumberObj.isValid() : false);
    } catch (error) {
      setIsValidPhoneNumber(false);
    }
  };

  const handleInputKeyPress = (event) => {
    const maxLength = 16; // Maximum length requirement
    const inputValue = event.target.value;

    if (
      inputValue.length >= maxLength &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      event.preventDefault();
    }
  };
  // const handleCouponCode = (e) => {
  //   e.preventDefault();
  //   const couponCodeString = 'conscious20';
  //   if (formData?.couponCode.toLowerCase() === couponCodeString.toLowerCase()) {
  //     setIsAppliedCouponCode(true);
  //     setIsCouponSuccess('Coupon code applied successfully!');
  //     calculateDiscount();
  //   } else {
  //     setIscouponError('Invalid coupon code');
  //     setTimeout(() => {
  //       setIscouponError('');
  //     }, 4000);
  //     SetIsLoading(false);
  //   }
  // };

  // const handleRemoveCouponCode = (e) => {
  //   setIsAppliedCouponCode(false);
  //   removeDiscount(e);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     couponCode: '',
  //   }));
  // };

  // const handleQuantity = (e) => {
  //   setQuantity(e);
  //   setIsAppliedCouponCode(false);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     couponCode: '',
  //   }));
  // };

  const [cardBrand, setCardBrand] = useState(null);

  useEffect(() => {
    if (stripe && elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (cardNumberElement) {
        cardNumberElement.on('change', (event) => {
          if (event.brand) {
            setCardBrand(event.brand);
          }
        });
      }
    }
  }, [stripe, elements]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row g-0">
          {paymentMethodSelected === 'Card' && (
            <>
              <div className="col-md-12">
                <PhoneNumberField
                  name="phoneNumber"
                  international
                  countryCallingCodeEditable={false}
                  placeholder="Phone Number*"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  defaultCountry="US"
                  error={!isValidPhoneNumber}
                  onKeyPress={handleInputKeyPress}
                  required
                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  placeholder="Name*"
                  name="name"
                  value={formData.name}
                  required
                  onChange={handleInputChange}
                  className="input-width border-b-r b-t-r"
                  onKeyPress={(e) => {
                    const keyCode = e.charCode || e.keyCode;
                    // Allow only numerics, letters, and spaces
                    if (
                      !(
                        (keyCode >= 48 && keyCode <= 57) || // Numerics
                        (keyCode >= 65 && keyCode <= 90) || // Uppercase letters
                        (keyCode >= 97 && keyCode <= 122) || // Lowercase letters
                        keyCode === 32
                      )
                    ) {
                      // Space
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    // Prevent pasting of special characters
                    const pastedText = e.clipboardData.getData('text/plain');
                    if (!/^[a-zA-Z0-9\s]+$/.test(pastedText)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-md-12">
                <input
                  type="email"
                  name="email"
                  placeholder="Email*"
                  value={formData.email}
                  required
                  onChange={handleInputChange}
                  className="input-width border-b-r"
                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  placeholder="Zip Code*"
                  name="zipCode"
                  maxLength="5"
                  onKeyPress={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  required
                  onChange={handleInputChange}
                  className="input-width border-b-r"
                />
              </div>
              <div className="col-md-6">
                <div className="atm-card">
                  <CardNumberElement
                    className="stripe-input b-r-r b-b-r "
                    options={inputOptions}
                  />

                  <div className="atm-card-image">
                    {!isMobile && (
                      <>
                        {cardBrand !== 'unknown' && cardBrand !== null ? (
                          <img
                            src={`/assets/cards/${cardBrand}.svg`}
                            alt={`${cardBrand} card`}
                          />
                        ) : (
                          cardBrandList.map((item, index) => (
                            <img
                              src={`/assets/cards/${item}.svg`}
                              alt={`${item} card`}
                              key={index}
                            />
                          ))
                        )}
                      </>
                    )}
                    {isMobile && (
                      <>
                        {cardBrand !== 'unknown' && cardBrand !== null && (
                          <img
                            src={`/assets/cards/${cardBrand}.svg`}
                            alt={`${cardBrand} card`}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <CardExpiryElement
                  className="stripe-input b-r-r m-b-r "
                  options={inputOptions}
                />
              </div>
              <div className="col-md-3 col-6">
                <CardCvcElement
                  className="stripe-input  m-b-r"
                  options={inputOptions}
                />
              </div>
            </>
          )}
        </div>
        {/* Phone no old changes-- Start*/}
        {/* <div className="col-lg-10 payment-phone">
            <PhoneNumberField
              name="phoneNumber"
              international
              countryCallingCodeEditable={false}
              placeholder="Phone Number*"
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="US"
              required
            />
            <span className="d-block d-lg-none text-end w-100">
              <Link onClick={handleChangeNumber}>Change Phone Number</Link>
            </span>
          </div> */}
        {/* Phone no old changes-- End*/}
        <div className="col-lg-12 px-md-5 px-0">
          {isError && <h5 className="error text-center">{errorMsg}</h5>}
        </div>
        {!isValidPhoneNumber && phoneNumber?.length > 4 && (
          <h6 className="error text-center">Invalid phone number</h6>
        )}
        {isCouponError && (
          <h6 className="text-heading text-center text-danger">
            {isCouponError}
          </h6>
        )}
        {isAppliedCouponCode && (
          <h6 className="text-center text-success text-nowrap overflow-hidden">
            {isCouponSuccess}
          </h6>
        )}
        <div className="col-lg-12 px-md-5 px-0">
          <button
            type="submit"
            className={
              !stripe || !elements || !isValidPhoneNumber
                ? 'theme-button disable-button w-100 mt-3'
                : 'theme-button w-100 mt-3'
            }
            disabled={!stripe || !elements || !isValidPhoneNumber}
          >
            {isLoading ? (
              <ThreeDots
                height="20"
                width="80"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: 'contents' }}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              'Proceed Checkout'
            )}
          </button>
        </div>
      </form>
    </>
  );
};

const Payment = ({
  setCheckPayment,
  eventId,
  setQuantity,
  quantity,
  calculateDiscount,
  removeDiscount,
  selectedPass,
  setSelectedDay,
  selectedDay,
  setPaymentMethodSelected,
  paymentMethodSelected,
  setCouponCodeState,
  setReferralCode,
  referralCode,
  couponCode,
}) => {
  const [isPhoneNumberVerified, SetIsPhoneNumberVerified] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  return (
    <>
      {/* {!isPhoneNumberVerified && (
        <PhoneNumberVerfiy
          SetIsPhoneNumberVerified={SetIsPhoneNumberVerified}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )} */}
      {isPhoneNumberVerified && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            setCheckPayment={setCheckPayment}
            eventId={eventId}
            setQuantity={setQuantity}
            quantity={quantity}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            SetIsPhoneNumberVerified={SetIsPhoneNumberVerified}
            calculateDiscount={calculateDiscount}
            removeDiscount={removeDiscount}
            selectedPass={selectedPass}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
            setPaymentMethodSelected={setPaymentMethodSelected}
            paymentMethodSelected={paymentMethodSelected}
            setCouponCodeState={setCouponCodeState}
            setReferralCode={setReferralCode}
            referralCode={referralCode}
            couponCode={couponCode}
          />
        </Elements>
      )}
    </>
  );
};

Payment.propTypes = {
  setCheckPayment: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  calculateDiscount: PropTypes.func.isRequired,
  removeDiscount: PropTypes.func.isRequired,
  selectedPass: PropTypes.object.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
  selectedDay: PropTypes.string.isRequired,
  setPaymentMethodSelected: PropTypes.func.isRequired,
  paymentMethodSelected: PropTypes.string.isRequired,
  setCouponCodeState: PropTypes.string.isRequired,
  setReferralCode: PropTypes.string.isRequired,
  referralCode: PropTypes.string.isRequired,
  couponCode: PropTypes.string.isRequired,
};

CheckoutForm.propTypes = {
  setCheckPayment: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  SetIsPhoneNumberVerified: PropTypes.func.isRequired,
  calculateDiscount: PropTypes.func.isRequired,
  removeDiscount: PropTypes.func.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
  selectedDay: PropTypes.string.isRequired,
  selectedPass: PropTypes.object.isRequired,
  setPaymentMethodSelected: PropTypes.func.isRequired,
  paymentMethodSelected: PropTypes.string.isRequired,
  setCouponCodeState: PropTypes.string.isRequired,
  setReferralCode: PropTypes.string.isRequired,
  referralCode: PropTypes.string.isRequired,
  couponCode: PropTypes.string.isRequired,
};

export default Payment;

import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
// import { Link } from 'react-router-dom';
import { API_GET_ALL_PLAN } from '../config/Endpoints';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import LinkSinger from '../Components/LinkSinger';
import { Link, useLocation } from 'react-router-dom';

const PlanVisit = () => {
  const [plan, setPlan] = useState([]);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const fetchPlan = async () => {
    setLoading(true);
    await fetch(API_GET_ALL_PLAN, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setPlan(result.data);
      })
      .catch((error) => console.log('error', error));
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    <>
      <MainLayout>
        <Helmet>
          <title>PLAN YOUR VISIT</title>
          <meta
            name="description"
            content=" Awake Embrace is a four-day retreat split across two weekends, starting weekend on May 4-7 and ending weekend on May 11-14. All music events will take place in and around a group of private gardens close to the city of San Jose Del Cabo."
          />
        </Helmet>
        <section className="hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="plan-text">
                  <h1 className="main-heading">PLAN YOUR VISIT</h1>
                  <p className="text-heading">
                    Conscious Kiss is a 1 night Experience with limited
                    capacity. The intimacy of the Festival is intentional to
                    create a unique experience. The event takes place March 1,
                    2024 at Crania Reclaim in San Jose Del Cabo. We are
                    extremely excited about the Lineup, come for the Moments and
                    Magic - Stay for the Music!
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="">
                  <h3 className="closing">-Conscious moments in Baja await!</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="visit-accordion">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="plan-accordion" id="plan-acc">
                  {!loading &&
                    plan?.map((item) => (
                      <div className="plan-accordion__item" key={item.id}>
                        <h2
                          className="plan-accordion__header"
                          id={'heading' + item.id}
                        >
                          <button
                            className="plan-accordion__button accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={'#collapse' + item.id}
                            aria-expanded="false"
                            aria-controls={'collapse' + item.id}
                          >
                            {item.title}
                          </button>
                        </h2>
                        <div
                          id={'collapse' + item.id}
                          className={
                            state === 'vip_table' && item.title === 'VIP Tables'
                              ? 'accordion-collapse collapse show'
                              : 'accordion-collapse collapse'
                          }
                          aria-labelledby={'heading' + item.id}
                          data-bs-parent="#plan-acc"
                        >
                          <div className="plan-accordion__body">
                            <ul className="plan-accordion__listing-plan">
                              {parse(item.text)}

                              {item.title === 'VIP Tables' && (
                                <>
                                  <div className="place-select">
                                    <img
                                      src="../assets/images/vip-table-image-1.jpg"
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <img
                                      src="../assets/images/place-select-1.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <img
                                      src="../assets/images/fun.jpg"
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <img
                                      src="../assets/images/place.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <Link
                                    to="/choose-table"
                                    className="theme-button"
                                  >
                                    Reserve Table
                                  </Link>
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  {loading && (
                    <div className="text-center fs-5 p-5">Loading....</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <LinkSinger />
      </MainLayout>
    </>
  );
};

export default PlanVisit;

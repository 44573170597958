import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
          <div className="container">
            <Link to="/" className="Logo">
              <div className="logo-icon">
                <img
                  src="../assets/images/ck_logo.svg"
                  alt="Concious Kiss Logo"
                />
              </div>
              {/* <span>Conscious Kiss</span> */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <img src="../assets/images/iconmenu.png" alt="" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activeItem === '/' && 'active'} `}
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeItem === '/plan-visit' && 'active'
                    } `}
                    to="/plan-visit"
                  >
                    Plan Your Visit
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeItem === '/event-passes' && 'active'
                    } `}
                    to="/event-passes"
                  >
                    Event Pass
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      activeItem === '/line-up' && 'active'
                    } `}
                    to="/line-up"
                  >
                    Lineup
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

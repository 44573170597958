import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const LinkSinger = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleVIPTable = () => {
    const collapse = document.getElementById('collapse7');
    if (pathname === '/plan-visit') {
      navigate('/plan-visit', {
        state: 'vip_table',
      });
      window.scrollTo(0, 750);
      if (collapse) {
        collapse.classList.add('show');
      }
    } else {
      navigate('/plan-visit', {
        state: 'vip_table',
      });
      window.scrollTo(0, 750);
    }
  };
  return (
    <>
      <section className="extra-links">
        <div className="container">
          <div className="row">
            <hr className="my-5" />
            <ul>
              {/* <li>
                <Link to="/partners">Partners</Link>
              </li> */}
              <li>
                <Link to="/weekend-guide">The Night Guide</Link>
              </li>
              <li>
                <span onClick={handleVIPTable}>Vip Tables</span>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/consciouskissofficial/"
                  target="_blank"
                >
                  Instagram
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-condition">Terms & Condition</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
export default LinkSinger;

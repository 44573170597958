import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_GET_QUESTIONS, API_SEND_RESPONSE } from '../../config/Endpoints';

const CreatorOpportunity = () => {
  const [selectedRadio, setSelectedRadio] = useState('');
  const [isResponseSent, setIsResponseSent] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchQuestion();
    // // Check if the page is being reloaded
    // if (performance.navigation.type === 1) {
    //   // Navigate to the opportunity route on page reload
    //   window.location.href = '/opportunity';
    // }
  }, []);

  const handleRadioChange = (id) => {
    setSelectedRadio(id);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    var formdata = new FormData();
    formdata.append('question_id', selectedRadio);
    formdata.append('comments', description);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch(API_SEND_RESPONSE, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result), setIsResponseSent(true))
      .catch((error) => console.log('error', error));
  };

  const fetchQuestion = async () => {
    setLoading(true);
    await fetch(API_GET_QUESTIONS, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setQuestions(result.data.questions);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  return (
    <>
      <header className="header-top">
        <div className="space-con">
          <h1>It wasn't meant to be.</h1>
          <div className="choose-button">
            <Link to="/opportunity" className="text-sm">
              Back
            </Link>
          </div>
        </div>
      </header>
      <div className="tickets-vip">
        <div className="container-fluid px-0">
          <div className="row">
            <h3 className="heading-all">
              We’re sorry to see you’re not interested. Please tell us more.
            </h3>
            {loading && <div className="text-center fs-5 p-5">Loading....</div>}
            <div className="create-opp">
              {questions.map((item, index) => (
                <div className="form-check check-box-items" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radioCheck"
                    id={'radioCheck' + index}
                    checked={selectedRadio === item.id}
                    onChange={() => handleRadioChange(item.id)}
                    disabled={isResponseSent}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={'radioCheck' + index}
                  >
                    {item.question}
                  </label>
                </div>
              ))}
            </div>
            {selectedRadio && (
              <div className="col-md-12">
                <label htmlFor="Name" className="input-heading pt-4">
                  Tell us more (Optional)
                </label>

                <textarea
                  className="form-control new-input"
                  placeholder="Share anything else you would like us to Know"
                  id="floatingTextarea2"
                  style={{ height: '140px' }}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  readOnly={isResponseSent}
                ></textarea>
              </div>
            )}
            <div className="submit-button text-start">
              <button
                type="submit"
                className={
                  !selectedRadio || isResponseSent
                    ? 'application-button bg-inactive-button hover-disable'
                    : 'application-button bg-color-click'
                }
                onClick={!selectedRadio ? null : handleSubmit}
              >
                {isResponseSent ? 'Response Reported' : 'Submit Response'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorOpportunity;

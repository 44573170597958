const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const API_GET_ALL_PLAN = baseUrl + '/api/planyourvisit';
export const API_GET_ACTIVE_EVENT = baseUrl + '/api/events/active';
export const API_GET_ACTIVE_LINEUP = baseUrl + '/api/events/lineup';
export const API_POST_PURCHASE = baseUrl + '/api/events/';

export const API_VIP_TABLE = baseUrl + '/api/events/viptable';
export const API_VIP_ZONE = baseUrl + '/api/vip/getZones';
export const API_VIP_PURCHASE = baseUrl + '/api/vip/purchase';

export const API_SEND_OTP = baseUrl + '/api/events/sendotp';
export const API_VERIFY_OTP = baseUrl + '/api/events/verifyotp';
export const API_GET_QUESTIONS = baseUrl + '/api/affiliate/questions';
export const API_SEND_RESPONSE = baseUrl + '/api/affiliate/response';
export const API_SEND_REQUEST_FORM = baseUrl + '/api/affiliate/request';
export const API_KLARNA_INTENT = baseUrl + '/api/klarna/intent';
export const API_KLARNA_PURCHASE = baseUrl + '/api/klarna/purchase';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API_KLARNA_PURCHASE } from '../../config/Endpoints';
import { deleteLocalData } from '../../utils/storage';
import MainLayout from '../../Layout/MainLayout';

const AfterCheckout = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const redirectStatus = queryParams.get('redirect_status');
  const intentId = queryParams.get('id');

  useEffect(() => {
    if (redirectStatus === 'succeeded' && intentId) {
      sendDataToAPI();
    }
    const urlWithoutParams = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, urlWithoutParams);
  }, []);

  const sendDataToAPI = async () => {
    var formdata = new FormData();
    formdata.append('intent_id', intentId);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    await fetch(API_KLARNA_PURCHASE, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        deleteLocalData('referralCode');
        console.log(response?.status);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainLayout>
      <div className="row mt-5">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="thankyou pt-5 text-center text-md-start">
            {redirectStatus === 'succeeded' && (
              <div className="text-center">
                <h3 className="text-success ">Payment Successful!</h3>
                <h4 className="main-heading ">Thank you! 🎉</h4>
                <p className="fs-5 ">
                  We have sent you a confirmation email containing all the
                  details of your ticket.
                </p>
                <a href="/line-up" className="theme-button mt-4">
                  Checkout LineUp
                </a>
              </div>
            )}
            {redirectStatus === 'failed' && (
              <div className="text-center">
                <h3 className="text-danger ">Payment Failed!</h3>
                <h4 className="main-heading">Please Try Again!</h4>
                <p className="fs-5 ">
                  We regret to tell you that payment is failed due to unknown
                  reason.
                </p>
                <a href="/event-passes" className="theme-button mt-4">
                  Checkout Event Pass
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </MainLayout>
  );
};

export default AfterCheckout;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLocalData } from '../utils/storage';

const DiscountReferral = ({
  handleQuantity,
  quantity,
  discount,
  couponCode,
  setCouponCode,
  isAppliedCouponCode,
  handleCouponCode,
  handleRemoveCouponCode,
  referralCode,
  setReferralCode,
  isCouponSuccess,
  isCouponError,
  checkOutStep,
  setCheckOutStep,
  hasSpecialPrice,
}) => {
  const handleChange = (e) => {
    setCheckOutStep('step-1');
    handleRemoveCouponCode(e);
    if (!getLocalData('referralCode')) {
      setReferralCode('');
    }
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    if (e.target.value.length === 11) {
      handleCouponCode(e);
    }
  };

  useEffect(() => {
    if (isAppliedCouponCode === false) {
      setCouponCode('');
    }
  }, [isAppliedCouponCode]);

  return (
    <>
      {checkOutStep === 'step-2' && (
        <>
          <div className="checkout-step two mb-5">
            <a
              href="javascript:void(0)"
              onClick={handleChange}
              className="edit"
            >
              Edit details
            </a>
            {/* {
                (couponCode && isAppliedCouponCode) || referralCode
                  ? 'item'
                  : 'new-items'
              } */}
            <div className="item">
              <label>Number of Passes</label>
              <p>{quantity}</p>
            </div>

            {((couponCode && isAppliedCouponCode) || hasSpecialPrice) && (
              <div className="item d-none">
                <label>Discount</label>
                <p>${discount}</p>
              </div>
            )}

            {couponCode && isAppliedCouponCode && (
              <>
                <div className="item">
                  <label htmlFor="coupon">Coupon Code</label>
                  <input
                    type="text"
                    id="coupon"
                    value={couponCode}
                    readOnly
                    onChange={(e) => setCouponCode(e.target.value)}
                    disabled={isAppliedCouponCode}
                  />
                </div>
              </>
            )}

            {referralCode && (
              <div className="item">
                <label htmlFor="referral">Referral Code</label>
                <input
                  type="text"
                  id="referral"
                  value={referralCode}
                  readOnly
                  onChange={(e) => setReferralCode(e.target.value)}
                  disabled={getLocalData('referralCode') ? true : false}
                />
              </div>
            )}
          </div>
        </>
      )}
      {checkOutStep === 'step-1' && (
        <>
          <div className="checkout-step one">
            <div className="item">
              <label>Number of Passes</label>
              <select
                name="quantity"
                id="quantity"
                value={quantity}
                onChange={handleQuantity}
                className="passes-number-list"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="item d-none">
              <label>Discount</label>
              <p>${discount}</p>
            </div>
            <div className="item">
              <label htmlFor="coupon">Coupon Code</label>
              <input
                type="text"
                id="coupon"
                value={couponCode}
                placeholder="XXXXXXXXX"
                onChange={handleCouponChange}
                disabled={isAppliedCouponCode}
              />
            </div>
            <div className="item">
              <label htmlFor="referral">Referral Code</label>
              <input
                type="text"
                id="referral"
                value={referralCode}
                placeholder="XXXXXXXXX"
                onChange={(e) => setReferralCode(e.target.value)}
                disabled={getLocalData('referralCode') ? true : false}
              />
            </div>
            <div className="border-0 item checkout-error">
              {isCouponError && (
                <h6 className="text-danger ">{isCouponError}</h6>
              )}
              {isAppliedCouponCode && (
                <h6 className="text-success">{isCouponSuccess}</h6>
              )}
            </div>
            <button
              type="submit"
              className="theme-button w-100 mt-3"
              onClick={() => setCheckOutStep('step-2')}
            >
              Continue to Payment
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default DiscountReferral;

DiscountReferral.propTypes = {
  handleQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.string,
  discount: PropTypes.string,
  couponCode: PropTypes.string.isRequired,
  setCouponCode: PropTypes.func.isRequired,
  isAppliedCouponCode: PropTypes.bool.isRequired,
  handleCouponCode: PropTypes.func.isRequired,
  handleRemoveCouponCode: PropTypes.func.isRequired,
  referralCode: PropTypes.string.isRequired,
  isCouponSuccess: PropTypes.string,
  isCouponError: PropTypes.string,
  checkOutStep: PropTypes.string.isRequired,
  setCheckOutStep: PropTypes.func.isRequired,
  setReferralCode: PropTypes.func.isRequired,
  hasSpecialPrice: PropTypes.bool.isRequired,
};

import React from 'react';
import { Link } from 'react-router-dom';
import UserId from '../../Components/UserId';

const Opportunity = () => {
  return (
    <>
      <header className="header-top">
        <div className="space-con">
          <h1>Are you interested?</h1>
          <div className="choose-button">
            <Link to="/creator-opportunity" className="text-sm">
              No
            </Link>
            <Link to="/application" className="bg-blue test-sm">
              Yes
            </Link>
          </div>
        </div>
      </header>
      <section className="tickets-vip">
        <div className="container-fluid">
          <div className="row">
            <div className="vip-conscious">
              <div>
                <img src="../assets/images/girl-marks2.png" alt="" />
              </div>
              <div>
                <img src="../assets/images/CK-LOGO.svg" alt="" />
              </div>
            </div>
            <div className="artists">
              <UserId
                userImage="../assets/images/dj_joeski.jpg"
                userName="JOESKI"
                listImage="images-second"
              />
              <UserId
                userImage="../assets/images/dj_yokoo.png"
                userName="YokoO"
                listImage="images-second"
              />
            </div>
            <div className="artists space-add mb-4">
              <UserId
                userImage="../assets/images/Bobi-Stevkovski.png"
                userName="Bobi Stevkovski"
                listImage="images-third"
              />

              <UserId
                userImage="../assets/images/dj_Jessc.png"
                userName="Jess C"
                listImage="images-third"
              />
              <UserId
                userImage="../assets/images/Patricio.jpg"
                userName="Patricio Hegewish"
                listImage="images-third patricio"
              />
              <UserId
                userImage="../assets/images/Crowley.jpg"
                userName="Mr.Crowley"
                listImage="images-third"
              />
            </div>
            <hr />
            <div className="vip-text">
              <p className="simple-heading color-gray pt-0">
                The same Venue that brought you Sundream by RÜFÜS DU SOL -
                Presents! Conscious Kiss. A Masquerade Destination Experience.
                March 1-3, 2024 A weekend of Music provided by an incredible DJ
                line-up, wrapped in Masquerade, with a ritual of Reclaiming
                Moments.
              </p>
              <p className="simple-heading color-gray">
                Conscious Kiss is a 2 day Masquerade Destination Experience with
                limited capacity. The intimacy of the Festival is intentional to
                create a unique experience. The event takes place March 1st and
                2nd at Crania Reclaim in San Jose Del Cabo, MX. After the
                Festival we will host a Sunset Beach Masquerade on March 3rd at
                Veleros Beach Club which is steps away from Crania.
              </p>
            </div>
            <div className="compensated ">
              <h3 className="heading-all">How You Get Compensated</h3>
              <ul>
                <li>
                  Free tickets for you to any night of the festival!
                  <ul>
                    <li>
                      Plus, you will get a 10% commission for every pass or
                      table you help us sell with your unique link or referral
                      code.
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="img-product">
                <Link to="#">
                  <img src="../assets/images/two-2.jpeg" alt="" />
                </Link>
                <Link to="#">
                  <img src="../assets/images/party.png" alt="" />
                </Link>
              </div>
            </div>
            <div className="requirements">
              <h3 className="heading-all">
                Instagram Collaboration Requirements
              </h3>
              <ul>
                <li>You must be over the age of 21</li>
                <li>
                  <span className="fb">Post within 1 week</span>&nbsp; of being
                  selected to participate in the campaign
                </li>
                <li>
                  We encourage you to post Reels and Stories at the frequency
                  that suites the interaction level with your base that will
                  effectively drive the desired outcome of selling tickets
                </li>
                <li>
                  You can request to be paid monthly or all at once at the end
                  of the festival
                </li>
                <li>
                  Follow and tag our account &nbsp;
                  <span className="fb">
                    <Link to="/" className="color-black">
                      @consciouskissofficial
                    </Link>
                  </span>
                </li>
                <li>
                  Upon acceptance, you will be emailed a personalized link to
                  use in your bio or campaign link manager like Linktree
                </li>
                <li>
                  Upon acceptance, you will be emailed a personalized link for
                  your stories
                </li>
              </ul>
            </div>
            <div className="requirements">
              <h3 className="heading-all">Conscious Kiss Promo</h3>
              <p className="capture-text">
                We want you to create organic content that captures the essence
                of a Masquerade meets Burning Man, meets the Beach. It would be
                great if you could naturally weave in some promotional content
                while discussing traveling, Deep house music, and themed events
                like Masquerades. Our event at Crania next March is shaping up
                to be one of the biggest events of 2024. See you there!
              </p>

              <h3 className="need-heading">
                We need you to create these three pieces of content
              </h3>
              <ul>
                <li>
                  <span className="fb">Receiving your tickets</span>
                  <ul>
                    <li>
                      Create a video informing your followers that you have
                      received a ticket to one of our shows
                    </li>
                    <li>
                      Promote your link in your stories and encourage your
                      followers to purchase tickets
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="fb">Preparing for the event</span>
                  <ul>
                    <li>
                      Promote the festival while showing off how you are
                      preparing for the event
                    </li>
                    <li>
                      Show off your dope Mask, your outfit or costume that you
                      are going to wear to the festival
                    </li>
                    <li>
                      Share your promo code again so your audience can join you
                      at the festival
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="fb">Instagram Stories</span>
                  <ul>
                    <li>
                      Please post stories throughout your partnership with us
                    </li>
                    <li>
                      Share your promo code and a link so your audience can
                      purchase tickets
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="requirements">
              <h3 className="heading-all">Caption Inspiration</h3>
              <p className="color-light-gray font-size-in">
                Plans Next March? ✔️ Just got passes for one 2024’s Most unique
                events - Masquerade Destination Experience 🤍 Find me at
                @craniareclaim @consciouskissofficial #Consciouskiss
                #CKMasquerade #Consciouskissofficial March 1-3✨🎬 Join me:
                (insert personal ticket link)
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Opportunity;
